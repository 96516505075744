import { useState } from "react";
import { RyeMainContentCard } from "../rye/RyeMainContentCard";
import { buildClasses } from "../utils/buildClasses";
import { RyeMainContentHeader } from "../rye/RyeMainContentHeader";
import { RyeMainContentSpacer } from "../rye/RyeMainContentSpacer";
import { createContextWithSetter } from "../utils/ContextUtils";
import { TransactionsTable } from "./TransactionsTable";
import { TransactionColumn } from "./TransactionColumn";
import { RyeMainContentTitle } from "../rye/RyeMainContentTitle";
import { RyeEditableText } from "../rye/RyeEditableText";
import { BreadTransaction, P } from "breadcommon";

export enum SortOrders {
  Ascending,
  Descending,
}

export type TransactionsNav = {
  sortColumn: TransactionColumn;
  sortOrder: SortOrders;
  reviewedFilter: boolean | null;
};

const defaultTransactionsNav = {
  sortColumn: TransactionColumn.DATE,
  sortOrder: SortOrders.Descending,
  reviewedFilter: null,
};

export const TransactionsNavContext = createContextWithSetter<TransactionsNav>(
  defaultTransactionsNav
);

export function TransactionsScreen(): JSX.Element {
  const [transactionsNav, setTransactionsNav] = useState<TransactionsNav>(
    defaultTransactionsNav
  );
  const [searchStr, setSearchString] = useState<string>('');

  let transactionFilter = null;
  if (searchStr !== '') {
    transactionFilter = P.Any(
      P.SubstringCI(P.NestedField<BreadTransaction, 'merchant', 'name'>('merchant', 'name'), P.Literal(searchStr)),
      P.SubstringCI(P.Field<BreadTransaction, 'description'>('description'), P.Literal(searchStr)),
      P.SubstringCI(P.Field<BreadTransaction, 'type'>('type'), P.Literal(searchStr)),
      P.SubstringCI(P.Field<BreadTransaction, 'subtype'>('subtype'), P.Literal(searchStr)),
    )
  }

  return (
    <TransactionsNavContext.Provider
      value={[transactionsNav, setTransactionsNav]}
    >
      <div
        className={buildClasses(
          "w-full",
          "h-full",
          "flex",
          "flex-col",
          "flex-grow",
          "flex-shrink",
          "min-w-1"
        )}
      >
        <RyeMainContentHeader>
          <div className={"mr-1"}>Search transactions:</div>
          <RyeEditableText
            startingText=""
            save={(newText) => {
              console.log(newText);
              setSearchString(newText);
            }}
          />
        </RyeMainContentHeader>
        <RyeMainContentSpacer direction={"h"} />
        <RyeMainContentCard roundedTl={false} roundedTr={false}>
          {/* <div className={buildClasses("px-8", "py-6")}> */}
          <RyeMainContentTitle title={"Transactions"} />
          {/* </div> */}
          <TransactionsTable filter={transactionFilter} />
        </RyeMainContentCard>
      </div>
    </TransactionsNavContext.Provider>
  );
}
