import { useContext, useEffect, useState } from "react";
import {
  syncAccounts,
  syncTransactions,
} from "../firebaseio/firebaseFunctions";
import { firestoreUpdateTransactionsAgainstRules } from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { buildClasses } from "../utils/buildClasses";
import { RyeIcon } from "../rye/RyeIcon";

export function SyncTransactionsButton(): JSX.Element {
  const user = useContext(UserContext);
  const [transactionSyncInProgress, setTransationSyncInProgress] =
    useState<boolean>(false);

  async function syncTransactionsAndAccounts() {
    setTransationSyncInProgress(true);
    const transactionsPromise = syncTransactions();
    const accountsPromise = syncAccounts();
    try {
      await Promise.allSettled([transactionsPromise, accountsPromise]);
    } catch (error) {
      console.log(`Syncing encountered an error: ${error}`);
    }
    setTransationSyncInProgress(false);
  }

  useEffect(() => {
    syncTransactionsAndAccounts();
  }, []);

  return (
    <button
      className={buildClasses("cursor-pointer", "p-2", "relative", "group")}
      onClick={() => {
        firestoreUpdateTransactionsAgainstRules(user.uid);
        syncTransactionsAndAccounts();
      }}
      disabled={transactionSyncInProgress}
    >
      <RyeIcon
        name="sync"
        size={"custom"}
        className={buildClasses(
          {
            if: transactionSyncInProgress,
            then: "animate-spin",
          },
          "text-[1.2rem]",
          "text-on-surface-500",
          "group-hover:text-on-surface-800",
          "block" // https://stackoverflow.com/questions/45423874/button-height-is-greater-than-the-nested-contents-height
        )}
      ></RyeIcon>
      {transactionSyncInProgress ? null : <SyncTooltip text={"Sync"} />}
    </button>
  );
}

function SyncTooltip({ text }: { text: string }): JSX.Element {
  return (
    <div
      className={buildClasses(
        "absolute",
        "top-1/2",
        "-translate-y-1/2",
        "left-12",
        "bg-on-surface-500",
        "text-surface",
        "text-sm",
        "z-10",
        "shadow-md",
        "rounded-md",
        "px-5",
        "py-1",
        "capitalize",
        "transition-shadow",
        "hidden",
        "group-hover:block"
      )}
    >
      {text}
    </div>
  );
}
