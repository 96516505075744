import { MainNavScreens } from "breadcommon";
import { buildClasses } from "../utils/buildClasses";
import { BreadLogo } from "./BreadLogo";
import { MainNavButton } from "./MainNavButton";
import { SyncTransactionsButton } from "./SyncTransactionsButton";

export function MainNavMenu() {
  return (
    <div
      className={buildClasses(
        "h-full",
        "flex",
        "flex-col",
        "items-center",
        "justify-between",
        "pl-2",
        "flex-shrink-0",
        "flex-grow-0",
        "w-16",
        "pr-5",
        "isolate", // create a stacking context so the main nav shows over the other stacking contexts
        "z-50"
      )}
    >
      <div
        className={buildClasses(
          "flex",
          "flex-col",
          "items-center",
          "justify-between"
        )}
      >
        <div className="pt-3" />
        <BreadLogo />
        <div className="h-5" />
        <MainNavButton
          iconName={"account_balance"}
          mainNavScreen={MainNavScreens.ACCOUNTS}
        />
        <MainNavButtonSpacer />
        <MainNavButton
          iconName={"checklist_rtl"}
          mainNavScreen={MainNavScreens.TRANSACTIONS}
        />
        <MainNavButtonSpacer />
        <MainNavButton
          iconName={"balance"}
          mainNavScreen={MainNavScreens.BUDGETS}
        />
      </div>
      <div>
        <MainNavButton
          iconName={"category"}
          mainNavScreen={MainNavScreens.CATEGORIES}
        />
        <MainNavButtonSpacer />
        <MainNavButton
          iconName={"gavel"}
          mainNavScreen={MainNavScreens.RULES}
        />
        <MainNavButtonSpacer />
        <MainNavButton
          iconName={"settings"}
          mainNavScreen={MainNavScreens.SETTINGS}
        />
        <MainNavButtonSpacer />
        <SyncTransactionsButton />
      </div>
    </div>
  );
}

function MainNavButtonSpacer(): JSX.Element {
  return <div className="h-3" />;
}
