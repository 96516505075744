import { TransactionRow } from "./TransactionRow";
import { useContext } from "react";
import { TransactionsContext } from "../firebaseio/TransactionsContext";
import { AccountsContext } from "../firebaseio/AccountsContext";
import {
  BreadAccount,
  BreadTransaction,
  FirestoreDocCategory,
  Predicate,
} from "breadcommon";
import dayjs from "dayjs";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { LoadingInitialDataContext } from "../LoadUser";
import { TransactionsHeader } from "./TransactionsHeader";
import { buildClasses } from "../utils/buildClasses";
import { TransactionsNavContext, SortOrders } from "./TransactionsScreen";
import { TransactionColumn } from "./TransactionColumn";

export function TransactionsTable({
  filter,
}: {
  filter: Predicate | null
}): JSX.Element {
  let transactions = useContext(TransactionsContext);
  const accounts = useContext(AccountsContext);
  const categories = useContext(CategoriesContext);
  const loadingInitialData = useContext(LoadingInitialDataContext);
  const [transactionsNav] = useContext(TransactionsNavContext);

  if (
    loadingInitialData.transactions ||
    loadingInitialData.categories ||
    loadingInitialData.accounts ||
    loadingInitialData.institutions ||
    loadingInitialData.rules
  ) {
    // TODO: add better loading animation
    return <TransactionsHeader />;
  }

  // TODO better optimization for ID-based filters
  // TODO combine transactionsNav.reviewedFilter into predicate filter

  const sortedTransactions = sortTransactions(
    filterTransactions(
      Array.from(transactions.values()),
      transactionsNav.reviewedFilter
    ).filter(transaction => filter == null || filter.applyTo(transaction)),
    transactionsNav.sortColumn,
    transactionsNav.sortOrder,
    accounts,
    categories
  );

  let transactionRows = sortedTransactions.map((t) => (
    <TransactionRow key={t.id} transaction={t}></TransactionRow>
  ));

  return (
    <div
      className={buildClasses(
        "flex",
        "flex-col",
        "min-w-[1100px]",
        "-mx-4",
        "-mt-2"
      )}
    >
      <TransactionsHeader />
      <div
        className={buildClasses("w-full", "h-full", "flex-grow", "flex-shrink")}
      >
        {transactionRows}
      </div>
      <div
        className={buildClasses(
          "flex",
          "justify-center",
          "h-[50vh]",
          "pt-[15vh]",
          "font-light",
          "text-on-surface-400"
        )}
      >
        End of transactions list.
      </div>
    </div>
  );
}

function filterTransactions(
  transactions: BreadTransaction[],
  reviewedFilter: boolean | null
): BreadTransaction[] {
  if (reviewedFilter === null) {
    return transactions;
  } else {
    return transactions.filter((t) => t.reviewed === reviewedFilter);
  }
}

function applySortOrder(value: number, order: SortOrders) {
  if (order === SortOrders.Ascending) {
    return value;
  }
  return -value;
}

function getCompareFunction(
  col: TransactionColumn,
  order: SortOrders,
  accounts: Map<string, BreadAccount>,
  categories: Map<string, FirestoreDocCategory>
): (a: BreadTransaction, b: BreadTransaction) => number {
  const dateComparisonFunction = (a: BreadTransaction, b: BreadTransaction) => {
    return applySortOrder(
      dayjs(a.date).valueOf() - dayjs(b.date).valueOf(),
      order
    );
  };

  if (col === TransactionColumn.ACCOUNT) {
    return (a: BreadTransaction, b: BreadTransaction) => {
      const accountNameA = accounts.get(a.account_id)?.name;
      const accountNameB = accounts.get(b.account_id)?.name;
      if (accountNameA === accountNameB) return dateComparisonFunction(a, b);
      if (!accountNameA) return applySortOrder(-1, order);
      if (!accountNameB) return applySortOrder(1, order);
      return applySortOrder(accountNameA.localeCompare(accountNameB), order);
    };
  }
  if (col === TransactionColumn.ORIGIN) {
    return (a: BreadTransaction, b: BreadTransaction) => {
      if (a.merchant === b.merchant) return dateComparisonFunction(a, b);
      if (!a.merchant) return applySortOrder(-1, order);
      if (!b.merchant) return applySortOrder(1, order);
      return applySortOrder(
        a.merchant.name.localeCompare(b.merchant.name),
        order
      );
    };
  }
  if (col === TransactionColumn.DESCRIPTION) {
    return (a: BreadTransaction, b: BreadTransaction) => {
      if (a.description === b.description) return dateComparisonFunction(a, b);
      if (!a.description) return applySortOrder(-1, order);
      if (!b.description) return applySortOrder(1, order);
      return applySortOrder(a.description.localeCompare(b.description), order);
    };
  }
  if (col === TransactionColumn.CATEGORY) {
    return (a: BreadTransaction, b: BreadTransaction) => {
      if (a.categoryId === b.categoryId) return dateComparisonFunction(a, b);
      if (!a.categoryId) return applySortOrder(-1, order);
      if (!b.categoryId) return applySortOrder(1, order);
      const categoryA = categories.get(a.categoryId)?.name;
      const categoryB = categories.get(b.categoryId)?.name;
      if (!categoryA) return applySortOrder(-1, order);
      if (!categoryB) return applySortOrder(1, order);
      return applySortOrder(categoryA.localeCompare(categoryB), order);
    };
  }
  if (col === TransactionColumn.AMOUNT) {
    return (a: BreadTransaction, b: BreadTransaction) => {
      if (a.amount === b.amount) return dateComparisonFunction(a, b);
      return applySortOrder(a.amount - b.amount, order);
    };
  }

  return dateComparisonFunction;
}

function sortTransactions(
  transactions: BreadTransaction[],
  col: TransactionColumn,
  order: SortOrders,
  accounts: Map<string, BreadAccount>,
  categories: Map<string, FirestoreDocCategory>
): BreadTransaction[] {
  return transactions.sort(
    getCompareFunction(col, order, accounts, categories)
  );
}
