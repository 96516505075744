import { buildClasses } from "../utils/buildClasses";
import { useEffect, useState } from "react";

export function RyeToggle({
  initIsChecked,
  onChange,
  disabled = false,
}: {
  initIsChecked: boolean;
  onChange: (newState: boolean) => void;
  disabled?: boolean;
}): JSX.Element {
  const [isChecked, setIsChecked] = useState(initIsChecked);

  useEffect(() => {
    setIsChecked(initIsChecked);
  }, [initIsChecked]);

  function handleClick() {
    onChange(!isChecked);
    setIsChecked((prevState) => !prevState);
  }

  return (
    <div
      className={buildClasses(
        {
          if: isChecked,
          then: buildClasses("bg-purple-500"),
          else: buildClasses("bg-on-surface-300"),
        },
        "rounded-full",
        "p-1",
        "flex",
        "cursor-pointer",
        "transition-colors",
        "w-11"
      )}
      onClick={handleClick}
    >
      <TransitioningSpacer isExpanded={isChecked} />
      <div
        className={buildClasses(
          "w-4",
          "h-4",
          "bg-on-surface-50",
          "rounded-full"
        )}
      />
      <TransitioningSpacer isExpanded={!isChecked} />
    </div>
  );
}

function TransitioningSpacer(props: { isExpanded: boolean }): JSX.Element {
  return (
    <div
      className={buildClasses(
        { if: props.isExpanded, then: "w-5", else: "w-0" },
        "transition-all"
      )}
    ></div>
  );
}
