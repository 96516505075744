import { FirestoreDocCategory } from "breadcommon";
import { buildClasses } from "../utils/buildClasses";
import { RyeEditableText } from "../rye/RyeEditableText";
import { useContext } from "react";
import { firestoreUpdateCategory } from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { RyeEmojiPicker } from "../rye/RyeEmojiPicker";
import { RyeButton } from "../rye/RyeButton";
import { FocusCategoryIndexContext } from "./CategoriesScreen";
import { RyeDropDown } from "../rye/RyeDropDown";
import { CATEGORY_TYPE_VALUES } from "breadcommon";
import { CategoryType } from "breadcommon";

export function CategoryItem({
  category,
  openDeleteCategoryModal,
}: {
  category: FirestoreDocCategory;
  openDeleteCategoryModal: () => void;
}): JSX.Element {
  const user = useContext(UserContext);
  const [focusCategoryIndex, setFocusCategoryIndex] = useContext(
    FocusCategoryIndexContext
  );

  function updateCategoryName(newName: string) {
    firestoreUpdateCategory(user.uid, category.id, {
      name: newName,
    });
  }

  function updateCategoryEmoji(newEmoji: string) {
    firestoreUpdateCategory(user.uid, category.id, {
      emoji: newEmoji,
    });
  }

  function updateCategoryType(newType: CategoryType) {
    firestoreUpdateCategory(user.uid, category.id, {
      type: newType,
    });
  }

  return (
    <div
      className={buildClasses(
        "w-full",
        "text-md",
        "h-10",
        "flex",
        "items-center",
        "group/categoryitem",
        "group/ryesearchabledropdown",
        "group/ryeeditabletext",
        "group/ryeemojipicker"
      )}
    >
      <div className={buildClasses("flex-grow-0", "flex-shrink-0")}>
        <RyeEmojiPicker
          initialEmoji={category.emoji}
          save={updateCategoryEmoji}
          height="sm"
          groupHover={true}
        />
      </div>
      <div className={buildClasses("w-0.5", "flex-shrink-0")} />
      <RyeEditableText
        startingText={category.name}
        save={updateCategoryName}
        focus={
          focusCategoryIndex?.groupId === category.category_group_id &&
          focusCategoryIndex?.categoryIndex === category.order_position
        }
        ackFocus={() => setFocusCategoryIndex(null)}
        size="sm"
        width="xl"
        groupHover={true}
      />
      <div className={buildClasses("w-10", "flex-shrink-0")} />
      <RyeDropDown<CategoryType>
        entries={CATEGORY_TYPE_VALUES.map((type) => {
          return { id: type, text: type, icon: null };
        })}
        initEntryId={category.type}
        onSelectEntry={(entry) => updateCategoryType(entry.id)}
        searchable
        size="sm"
        variant="transparent"
        groupHover={true}
      ></RyeDropDown>
      <div className={buildClasses("w-10", "flex-shrink-0", "flex-grow")} />
      <div
        className={buildClasses(
          "invisible",
          "group-hover/categoryitem:visible"
        )}
      >
        <RyeButton
          size="sm"
          icon="delete"
          variant="transparent"
          vibe="subdued"
          onClick={openDeleteCategoryModal}
        />
      </div>
    </div>
  );
}
