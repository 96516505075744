import {
  BreadTransaction,
  FirestoreDocRule,
  getNullFieldValueCriterionEquivalent,
  transactionMatchesSpecificRule,
} from "breadcommon";
import { BasicModal } from "../common/BasicModal";
import { useContext, useState } from "react";
import {
  firestoreDeleteRuleAndUpdateTransactions,
  firestoreUpdateRuleAndUpdateTransactions,
} from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { Checkbox } from "@mui/material";
import { RulePreview } from "../rules_screen/RulesScreen";
import { TransactionRowContext } from "./TransactionRow";
import { buildClasses } from "../utils/buildClasses";
import { RyeButton } from "../rye/RyeButton";
import { RyeIcon } from "../rye/RyeIcon";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { RyeDropDown } from "../rye/RyeDropDown";

export const InlineRuleEditorModal = (props: {
  rule: FirestoreDocRule;
  transaction: BreadTransaction;
  isOpen: boolean;
  close: () => void;
}) => {
  const user = useContext(UserContext);
  const { ruleForFieldHighlighting } = useContext(TransactionRowContext);
  const [modifiedRule, setModifiedRule] = useState<FirestoreDocRule>(
    props.rule
  );

  // There is some complexity here because criteriaIncludesDescription
  // is computed from modifiedRule, so the state setting needs to be done carefully.
  const [descriptionMatchType, _setDescriptionMatchType] = useState(
    modifiedRule.criteria.partial_description == null ? "exact" : "contains"
  );
  const [partialDescription, _setPartialDescription] = useState<string | null>(
    props.transaction.description
  );
  const criteriaIncludesDescription =
    modifiedRule.criteria.description != null ||
    modifiedRule.criteria.partial_description != null;
  const setDescriptionCriteria = (
    criteriaIncludesDescription: boolean,
    descriptionMatchType: string,
    partialDescription: string | null
  ) => {
    _setPartialDescription(partialDescription);
    _setDescriptionMatchType(descriptionMatchType);
    if (criteriaIncludesDescription) {
      if (descriptionMatchType === "exact") {
        matchField("description", props.transaction.description);
        ignoreField("partial_description");
      } else {
        matchField("partial_description", partialDescription);
        ignoreField("description");
      }
    } else {
      ignoreField("description");
      ignoreField("partial_description");
    }
  };

  const modifyRule = (field: string, value: string | null) => {
    setModifiedRule((prevRule) => ({
      ...prevRule,
      criteria: {
        ...prevRule.criteria,
        [field]: value,
      },
    }));
  };
  const ignoreField = (field: string) => {
    modifyRule(field, null);
  };
  const matchField = (field: string, value: string | null | undefined) => {
    modifyRule(
      field,
      value == null ? getNullFieldValueCriterionEquivalent() : value
    );
  };

  const cancel = () => {
    setModifiedRule(props.rule);
    setDescriptionCriteria(
      criteriaIncludesDescription,
      modifiedRule.criteria.partial_description == null ? "exact" : "contains",
      props.transaction.description
    );
    props.close();
  };

  return (
    <BasicModal isOpen={props.isOpen} width={860} height={800}>
      <div
        className={buildClasses(
          "flex",
          "h-full",
          "w-full",
          "flex-col",
          "justify-between",
          "p-9"
        )}
      >
        <div
          className={buildClasses(
            "flex",
            "items-center",
            "justify-between",
            "w-full"
          )}
        >
          <div className={buildClasses("text-lg", "font-medium")}>
            Edit Rule
          </div>
          <RyeButton
            icon="close"
            size={"md"}
            onClick={() => cancel()}
            variant="transparent"
            vibe="subdued"
            shape="fully-round"
          />
        </div>
        <div className={"h-5"} />
        <OverlayScrollbarsComponent
          className={buildClasses("w-full", "overflow-scroll")}
        >
          <div
            className={buildClasses(
              "w-full",
              "text-on-surface-500",
              "text-sm",
              "p-4",
              "bg-surface-300",
              "rounded-lg"
            )}
          >
            You are editing rule {props.rule.id} using transaction{" "}
            {props.transaction.id}. Use the checkboxes on the right to select
            which parts of the transaction you want to use for this rule. Your
            changes will not be saved or applied to your transactions until you
            hit Save. When you Save, it will apply to all Unreviewed
            transactions. If a Transaction is already marked as Reviewed, then
            it will not be impacted.
          </div>
          {transactionMatchesSpecificRule(
            props.transaction,
            modifiedRule
          ) ? null : (
            <div
              className={buildClasses(
                "mt-5",
                "rounded-lg",
                "p-6",
                "bg-red-100"
              )}
            >
              WARNING: your updated rule does not match this transaction
            </div>
          )}
          <div className={"h-5"} />
          <div className={buildClasses("border", "rounded-lg", "p-4", "pl-6")}>
            <div
              className={buildClasses(
                "flex",
                "justify-between",
                "items-center"
              )}
            >
              <div>
                Account ID is{" "}
                {props.transaction.account_id == null
                  ? "empty"
                  : `exactly "${props.transaction.account_id}"`}
              </div>
              <div>
                <Checkbox
                  checked={modifiedRule.criteria.account_id !== null}
                  onChange={(e) =>
                    e.target.checked
                      ? matchField("account_id", props.transaction.account_id)
                      : ignoreField("account_id")
                  }
                />
              </div>
            </div>
            <div
              className={buildClasses(
                "flex",
                "items-center",
                "justify-between"
              )}
            >
              <div>
                Merchant is{" "}
                {props.transaction.merchant?.name == null
                  ? "empty"
                  : `exactly "${props.transaction.merchant?.name}"`}
              </div>
              <div>
                <Checkbox
                  checked={modifiedRule.criteria.merchant_name !== null}
                  onChange={(e) =>
                    e.target.checked
                      ? matchField(
                          "merchant_name",
                          props.transaction.merchant?.name
                        )
                      : ignoreField("merchant_name")
                  }
                />
              </div>
            </div>
            <div
              className={buildClasses(
                "flex",
                "items-center",
                "justify-between"
              )}
            >
              <div>
                Type is{" "}
                {props.transaction.type == null
                  ? "empty"
                  : `exactly "${props.transaction.type}"`}
              </div>
              <div>
                <Checkbox
                  checked={modifiedRule.criteria.type !== null}
                  onChange={(e) =>
                    e.target.checked
                      ? matchField("type", props.transaction.type)
                      : ignoreField("type")
                  }
                />
              </div>
            </div>
            <div
              className={buildClasses(
                "flex",
                "items-center",
                "justify-between"
              )}
            >
              <div>
                Subtype is{" "}
                {props.transaction.subtype == null
                  ? "empty"
                  : `exactly "${props.transaction.subtype}"`}
              </div>
              <div>
                <Checkbox
                  checked={modifiedRule.criteria.subtype !== null}
                  onChange={(e) =>
                    e.target.checked
                      ? matchField("subtype", props.transaction.subtype)
                      : ignoreField("subtype")
                  }
                />
              </div>
            </div>
            {
              <div
                className={buildClasses(
                  "flex",
                  "items-center",
                  "justify-between"
                )}
              >
                <div
                  className={buildClasses(
                    "flex",
                    "items-center",
                    "justify-between"
                  )}
                >
                  Description
                  {props.transaction.description == null ? (
                    " is empty"
                  ) : (
                    <div
                      className={buildClasses(
                        "flex",
                        "items-center",
                        "justify-between"
                      )}
                    >
                      <div className={"w-5"} />
                      <RyeDropDown
                        initEntryId={"exact"}
                        entries={[
                          { id: "exact", text: "is exactly", icon: null },
                          { id: "contains", text: "contains", icon: null },
                        ]}
                        onSelectEntry={(entry) =>
                          setDescriptionCriteria(
                            criteriaIncludesDescription,
                            entry.id,
                            partialDescription
                          )
                        }
                        width="sm"
                      />
                      <div className="w-5" />
                      {descriptionMatchType === "exact" ? (
                        props.transaction.description
                      ) : (
                        <div
                          className={buildClasses(
                            "flex",
                            "items-center",
                            "border",
                            "rounded-lg",
                            "h-10",
                            "w-80"
                          )}
                        >
                          <input
                            type="text"
                            value={partialDescription ?? ""}
                            onChange={(e) =>
                              setDescriptionCriteria(
                                criteriaIncludesDescription,
                                descriptionMatchType,
                                e.target.value
                              )
                            }
                            className={buildClasses(
                              "px-3",
                              "border-none",
                              "rounded-lg",
                              "flex-grow"
                            )}
                            maxLength={
                              props.transaction.description.length + 100
                            }
                          />
                          {partialDescription !==
                            props.transaction.description && (
                            <RyeIcon
                              name="replay"
                              className={buildClasses(
                                "flex-grow-0",
                                "px-2",
                                "text-on-surface-500",
                                "cursor-pointer"
                              )}
                              tooltip="Reset to the transaction's description"
                              onClick={() =>
                                setDescriptionCriteria(
                                  criteriaIncludesDescription,
                                  descriptionMatchType,
                                  props.transaction.description
                                )
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <Checkbox
                    checked={criteriaIncludesDescription}
                    onChange={(e) =>
                      setDescriptionCriteria(
                        e.target.checked,
                        descriptionMatchType,
                        partialDescription
                      )
                    }
                  />
                </div>
              </div>
            }
          </div>
          <div className="h-6" />
          <div
            className={buildClasses("w-full", "p-6", "rounded-lg", "border")}
          >
            <div className={buildClasses("font-medium")}>Rule Preview</div>
            <div className="h-3" />
            <RulePreview rule={modifiedRule} />
          </div>
        </OverlayScrollbarsComponent>
        <div
          className={buildClasses(
            "flex",
            "items-center",
            "justify-between",
            "pt-3"
          )}
        >
          <div>
            {/* left footer button(s) */}
            <RyeButton
              icon="delete"
              text="Delete Rule"
              vibe={"danger"}
              variant="outlined"
              onClick={() => {
                firestoreDeleteRuleAndUpdateTransactions(
                  user.uid,
                  props.rule.id
                );
                ruleForFieldHighlighting.set(null);
                props.close();
              }}
            />
            {/* title="When you delete a rule, it will reapply all remaining rules to all 
            Unreviewed transactions to find the new best match (if one exists).
            If a Transaction is already marked as Reviewed, then it will not be impacted." */}
          </div>
          <div className={buildClasses("flex", "items-center")}>
            {/* right footer button(s) */}
            <RyeButton
              text="Cancel"
              variant="transparent"
              onClick={() => {
                ruleForFieldHighlighting.set(null);
                cancel();
              }}
            />
            <div className={"w-5"} />
            <RyeButton
              text="Save Changes"
              vibe="primary"
              variant="filled"
              onClick={() => {
                firestoreUpdateRuleAndUpdateTransactions(
                  user.uid,
                  props.rule.id,
                  modifiedRule
                );
                ruleForFieldHighlighting.set(null);
                props.close();
              }}
              disabled={Object.values(modifiedRule.criteria).every(
                (criteria) => criteria == null
              )}
              //   title="When you save, it will reapply all rules to all Unreviewed transactions to find the new best match.
              // If a Transaction is already marked as Reviewed, then it will not be impacted."
            />
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
