import { useContext, useEffect, useRef, useState } from "react";
import { getLetterOrNumberFromCode, keyCodes } from "../utils/KeyCodes";
import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentRef,
} from "overlayscrollbars-react";

import "overlayscrollbars/overlayscrollbars.css";
import { useClickOutsideElement, useKeyDown } from "../utils/customHooks";
import { TransactionRowContext } from "./TransactionRow";
import { FirestoreDocCategory } from "breadcommon";
import { firestoreUpdateTransaction } from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { buildClasses } from "../utils/buildClasses";
import { TransactionColumnSizer } from "./TransactionColumnSizer";
import { TransactionColumn } from "./TransactionColumn";
import { TRANSACTION_CELL_PADDING_X } from "./TransactionColumnSizer";

function ExpandedInlineCategory(props: {
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  transactionId: string;
  createNewRule: (categoryIdAction: string) => void;
}): JSX.Element {
  const [highlightIndex, setHighlightIndex] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const user = useContext(UserContext);
  const categories = useContext(CategoriesContext);
  const expandedContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<OverlayScrollbarsComponentRef>(null);
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);
  useClickOutsideElement(
    expandedContainerRef,
    () => props.setIsExpanded(false),
    true,
    document
  );

  const filteredCategories: FirestoreDocCategory[] =
    createFilteredCategoriesList();
  if (filteredCategories.length === 0 && highlightIndex != null) {
    setHighlightIndex(null);
  }

  useEffect(focusOnCreation, []);

  function focusOnCreation() {
    inputRef.current?.focus();
  }

  function showNoneOption() {
    return inputValue === "";
  }

  function createFilteredCategoriesList(): FirestoreDocCategory[] {
    if (showNoneOption()) {
      return [
        {
          id: "",
          name: "none",
          emoji: "",
          type: "Expense",
          created_timestamp_secs: 0,
          order_position: 0,
          category_group_id: "",
        },
        ...Array.from(categories.values()),
      ];
    }
    return Array.from(categories.values()).filter(
      (category: FirestoreDocCategory) =>
        category.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  function handleInputChanged(value: string) {
    setInputValue(value);
    setHighlightIndex(0);
  }

  function scrollItemIntoView(itemIndex: number) {
    itemRefs.current[itemIndex]?.scrollIntoView({
      block: "nearest",
      inline: "nearest",
      behavior: "smooth",
    });
  }

  function updateTransactionCategory(category: FirestoreDocCategory) {
    let categoryId: string | null = null;
    if (category.id) {
      categoryId = category.id;
    }
    firestoreUpdateTransaction(
      user.uid,
      props.transactionId,
      {
        manual_data: {
          category_id: categoryId,
        },
      },
      null
    );
  }

  function markReviewed(): void {
    firestoreUpdateTransaction(
      user.uid,
      props.transactionId,
      {
        manual_data: { reviewed: true },
      },
      null
    );
  }

  function choose(category: FirestoreDocCategory): void {
    updateTransactionCategory(category);
    props.setIsExpanded(false);
  }

  function handleKeyDown(
    event: React.KeyboardEvent<HTMLDivElement>,
    keyCode: string
  ): void {
    const lastCategoryIndex = filteredCategories.length - 1;
    switch (keyCode) {
      case keyCodes.UPARROW:
        event.preventDefault(); // don't want to scroll the transaction row too
        event.stopPropagation();
        if (filteredCategories.length === 0) {
          break;
        } else if (highlightIndex === null || highlightIndex === 0) {
          setHighlightIndex(lastCategoryIndex);
          scrollItemIntoView(lastCategoryIndex);
        } else {
          setHighlightIndex(highlightIndex - 1);
          scrollItemIntoView(highlightIndex - 1);
        }
        break;
      case keyCodes.DOWNARROW:
        event.preventDefault(); // don't want to scroll the transaction row too
        event.stopPropagation();
        if (filteredCategories.length === 0) {
          break;
        } else if (
          highlightIndex === null ||
          highlightIndex === lastCategoryIndex
        ) {
          setHighlightIndex(0);
          scrollItemIntoView(0);
        } else {
          setHighlightIndex(highlightIndex + 1);
          scrollItemIntoView(highlightIndex + 1);
        }
        break;
      case keyCodes.ENTER:
        if (event.repeat) return;
        if (highlightIndex !== null) {
          choose(filteredCategories[highlightIndex]);
          markReviewed();
          if (event.ctrlKey || event.metaKey) {
            props.createNewRule(filteredCategories[highlightIndex].id);
          }
        } else {
          event.stopPropagation();
        }
        break;
      case keyCodes.ESC:
        event.stopPropagation();
        if (event.repeat) return;
        props.setIsExpanded(false);
        break;
      case keyCodes.TAB:
        event.stopPropagation();
        if (event.repeat) return;
        props.setIsExpanded(false);
        break;
      default:
        break;
    }
  }

  function createMenuItem(category: FirestoreDocCategory, i: number) {
    let displayText =
      category.emoji !== undefined && category.emoji !== ""
        ? ` ${category.emoji}   ${category.name}`
        : `        ${category.name}`;
    if (i === highlightIndex) {
    }
    if (i === 0 && showNoneOption()) {
      displayText = "  --    none";
    }
    return (
      <button
        key={`category${i}`}
        ref={(ref) => (itemRefs.current[i] = ref)}
        className={buildClasses(
          {
            if: i === highlightIndex,
            then: buildClasses("bg-purple-100"),
            else: buildClasses("bg-surface-50", "hover:bg-on-surface-100"),
          },
          {
            if: i === 0 && showNoneOption(),
            then: buildClasses("text-on-surface-400"),
          },
          "block",
          "w-full",
          "h-9",
          TRANSACTION_CELL_PADDING_X,
          "text-left",
          "border-none",
          "break-words",
          "cursor-pointer",
          "overflow-hidden",
          "text-ellipsis",
          "whitespace-pre",
          "rounded-none"
        )}
        onClick={() => {
          choose(filteredCategories[i]);
          markReviewed();
        }}
        tabIndex={-1}
      >
        {displayText}
      </button>
    );
  }

  var menu = filteredCategories
    .sort((a, b) => a.order_position - b.order_position)
    .map(createMenuItem);

  return (
    <div
      ref={expandedContainerRef}
      className={buildClasses("w-full", "h-full", "relative")}
      onKeyDown={(e) => handleKeyDown(e, e.key)}
    >
      <input
        type="text"
        ref={inputRef}
        className={buildClasses(
          {
            if: filteredCategories.length === 0,
            then: buildClasses("bg-red-50", "border-red", "shadow-red/20"),
            else: buildClasses("border-purple", "shadow-purple-500/20"),
          },
          "bg-purple-50",
          "outline-none",
          "box-border",
          "w-full",
          "h-full",
          "text-start",
          "rounded-lg",
          TRANSACTION_CELL_PADDING_X,
          "border-tiny",
          "shadow-glow"
        )}
        defaultValue={""}
        spellCheck="false"
        onChange={(e) => handleInputChanged(e.target.value)}
      />
      {/* options described at https://kingsora.github.io/OverlayScrollbars/ */}
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "never" },
          overflow: { x: "hidden" },
        }}
        className={buildClasses(
          {
            if: filteredCategories.length > 0,
            then: buildClasses("border-tiny", "border-on-surface-200"),
            else: buildClasses("border-none"),
          },
          "w-full",
          "absolute",
          "z-20",
          "overflow-hidden" /* hides extra material from buttons without border radius */,
          "rounded-lg",
          "max-h-48",
          "overflow-y-auto",
          "mt-2",
          "shadow-md"
          // "border",
          // "border-red"
        )}
        ref={menuRef}
      >
        {menu}
      </OverlayScrollbarsComponent>
    </div>
  );
}

function CollapsedInlineCategory(props: {
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  categoryId: string | null;
}): JSX.Element {
  const {
    transactionSelected,
    ruleForFieldHighlighting,
    keyListenerShouldListen,
  } = useContext(TransactionRowContext);
  const categories = useContext(CategoriesContext);
  const buttonRef = useRef<HTMLDivElement>(null);
  useKeyDown(buttonRef, keyListenerShouldListen, handleKeyDown);

  function handleKeyDown(event: KeyboardEvent): void {
    if (event.metaKey || event.ctrlKey) {
      // We want `cmd/ctrl + `enter` and `cmd/ctr` + `c` etc. to do other things
      // like mark reviewed or copy text.
      return;
    }
    if (event.code === keyCodes.TAB) {
      event.preventDefault();
      event.stopPropagation();
      if (event.repeat) return;
      props.setIsExpanded(true);
      return;
    }
    if (getLetterOrNumberFromCode(event.code)) {
      event.stopPropagation();
      if (event.repeat) return;
      props.setIsExpanded(true);
      return;
    }
  }

  let categoryDisplay = "";

  let category: FirestoreDocCategory | undefined = undefined;
  if (props.categoryId) {
    category = categories.get(props.categoryId);
  }
  if (category !== undefined) {
    categoryDisplay = category.name;
    if (category.emoji !== undefined) {
      categoryDisplay = `${category.emoji}   ${category.name}`;
    }
  }

  return (
    <div
      ref={buttonRef}
      className={buildClasses(
        {
          if: transactionSelected,
          then: buildClasses("hover:bg-purple-50", "hover:border-purple-400"),
          else: buildClasses(
            "hover:bg-surface-400",
            "hover:border-on-surface-300"
          ),
        },
        {
          if:
            ruleForFieldHighlighting.get !== null &&
            ruleForFieldHighlighting.get.action.category_id !== null,
          then: buildClasses("border-green", "shadow-glow", "shadow-green/20"),
          else: buildClasses("border-transparent", "shadow-none"),
        },
        "rounded-lg",
        "flex",
        "w-full",
        "h-full",
        "items-center",
        "text-start",
        "bg-transparent",
        "border-tiny",
        TRANSACTION_CELL_PADDING_X,
        "cursor-pointer"
      )}
      onClick={(e) => {
        props.setIsExpanded(true);
      }}
    >
      <div
        className={buildClasses(
          "block",
          "w-full",
          "whitespace-pre",
          "overflow-hidden",
          "overflow-ellipsis"
        )}
      >
        {categoryDisplay}
      </div>
    </div>
  );
}

export function TransactionColumnValueCategory(props: {
  transactionId: string;
  categoryId: string | null;
  createNewRule: (categoryIdAction: string) => void;
}): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (isExpanded) {
    return (
      <TransactionColumnSizer column={TransactionColumn.CATEGORY}>
        <ExpandedInlineCategory
          setIsExpanded={setIsExpanded}
          transactionId={props.transactionId}
          createNewRule={props.createNewRule}
        ></ExpandedInlineCategory>
      </TransactionColumnSizer>
    );
  }

  return (
    <TransactionColumnSizer column={TransactionColumn.CATEGORY}>
      <CollapsedInlineCategory
        setIsExpanded={setIsExpanded}
        categoryId={props.categoryId}
      ></CollapsedInlineCategory>
    </TransactionColumnSizer>
  );
}
