import { useContext } from "react";
import { PreferencesContext } from "../firebaseio/PreferencesContext";
import { buildClasses } from "../utils/buildClasses";
import styles from "./DollarAmount.module.scss";

function PlusMinus(props: {
  n_str: string;
  unrounded_num: number;
  showPlus: boolean;
  showMinus: boolean;
}) {
  // we can't just round() the number because Intl.NumberFormat
  // doesn't follow the same rules as round() so we won't always end up with
  // 0 under the exact same circumstances.
  if (parseInt(props.n_str) === 0) return null;
  if (props.unrounded_num > 0 && props.showPlus)
    return <span className={styles.sign}>+</span>;
  if (props.unrounded_num < 0 && props.showMinus)
    return <span className={styles.sign}>-</span>;
  return null;
}

export function DollarAmount({
  n,
  // use 2 decimal places instead of 0
  includeDecimals = false,
  showPlus = false,
  showMinus = false,
  showDollarSign = false,
  monospaceFont = true,
  align = "right",
}: {
  n: number;
  // use 2 decimal places instead of 0
  includeDecimals?: boolean;
  showPlus?: boolean;
  showMinus?: boolean;
  showDollarSign?: boolean;
  monospaceFont?: boolean;
  align?: "left" | "center" | "right";
}): JSX.Element {
  const userPrefs = useContext(PreferencesContext);
  let numDecimals = 2;
  if (!includeDecimals) {
    numDecimals = 0;
  }

  let n_str = new Intl.NumberFormat("en-US", {
    signDisplay: "never",
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  }).format(n);
  if (n === 0) {
    n_str = "--";
  } else if (userPrefs.web.hide_dollar_amounts) {
    n_str = '##';
  }

  return (
    <div
      className={
        buildClasses({ if: monospaceFont, then: "font-mono" }) +
        ` ${styles.amountContainer}  ${align === "left" ? styles.alignLeft : ""
        } ${align === "center" ? styles.alignCenter : ""}`
      }
    >
      <PlusMinus
        n_str={n_str}
        unrounded_num={n}
        showPlus={showPlus}
        showMinus={showMinus}
      />
      <span>{`${showDollarSign ? "$" : ""}${n_str}`}</span>
    </div>
  );
}
