import { DefaultTransactionColumnValue } from "./DefaultTransactionColumnValue";
import { TransactionColumn } from "./TransactionColumn";
import { TransactionColumnSizer } from "./TransactionColumnSizer";
import { useContext } from "react";
import { AccountsContext } from "../firebaseio/AccountsContext";
import { InstitutionsContext } from "../firebaseio/InstitutionsContext";
import { InstitutionLogo } from "../common/InstitutionLogo";
import { buildClasses } from "../utils/buildClasses";
import { TransactionRowContext } from "./TransactionRow";

export function TransactionColumnValueAccount({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const accounts = useContext(AccountsContext);
  const institutions = useContext(InstitutionsContext);
  const { ruleForFieldHighlighting } = useContext(TransactionRowContext);

  const ruleHighlight =
    ruleForFieldHighlighting.get !== null &&
    ruleForFieldHighlighting.get.criteria.account_id !== null;

  if (accountId == null) {
    return (
      <TransactionColumnSizer
        column={TransactionColumn.ACCOUNT}
      ></TransactionColumnSizer>
    );
  }
  const account = accounts.get(accountId);
  if (account === undefined) {
    return (
      <TransactionColumnSizer
        column={TransactionColumn.ACCOUNT}
      ></TransactionColumnSizer>
    );
  }

  const institution =
    account.institution_id !== null
      ? institutions.get(account.institution_id)
      : undefined;

  if (institution === undefined) {
    return (
      <TransactionColumnSizer column={TransactionColumn.ACCOUNT}>
        <DefaultTransactionColumnValue ruleHighlight={ruleHighlight}>
          {account.name}
        </DefaultTransactionColumnValue>
      </TransactionColumnSizer>
    );
  }

  return (
    <TransactionColumnSizer column={TransactionColumn.ACCOUNT}>
      <DefaultTransactionColumnValue ruleHighlight={ruleHighlight}>
        <InstitutionLogo institution={institution} size={18.5} />
        <div
          className={buildClasses(
            "w-100",
            "pl-3",
            "whitespace-nowrap",
            "overflow-hidden",
            "overflow-ellipsis"
          )}
        >
          {account.name}
        </div>
      </DefaultTransactionColumnValue>
    </TransactionColumnSizer>
  );
}
