import { BreadBudget } from "breadcommon";
import { firestoreDeleteBudget } from "../firebaseio/firestoreIo";
import { RyeButton } from "../rye/RyeButton";
import { RyeMainContentTitle } from "../rye/RyeMainContentTitle";
import { buildClasses } from "../utils/buildClasses";
import { useContext } from "react";
import { UserContext } from "../firebaseio/UserContext";
import { BudgetTrackingTable } from "./BudgetTrackingTable";

export function BudgetTracker(props: { budget: BreadBudget }): JSX.Element {
  const user = useContext(UserContext);

  return (
    <div className={buildClasses("h-full", "flex", "flex-col")}>
      <div className={buildClasses("flex", "items-center")}>
        <RyeMainContentTitle title={props.budget.name ?? ""} />
        <div className={buildClasses("w-10")}></div>
        <div>
          {props.budget.beginDateTimestampSecs.format("MMM D, YYYY")} —{" "}
          {props.budget.endDateTimestampSecs.format("MMM D, YYYY")}
        </div>
        <div className={buildClasses("ml-auto")}>
          <RyeButton
            icon={"delete"}
            variant="transparent"
            onClick={() => firestoreDeleteBudget(user.uid, props.budget.id)}
          />
        </div>
      </div>
      <BudgetTrackingTable budget={props.budget} />
    </div>
  );
}
