import { useContext } from "react";
import { UrlContext } from "../UrlContext";
import { MainNavScreens } from "breadcommon";
import { BudgetScreen } from "../budget_screen/BudgetScreen";
import { TransactionsScreen } from "../transactions_screen/TransactionsScreen";
import { buildClasses } from "../utils/buildClasses";
import { MainNavMenu } from "../main_nav/MainNavMenu";
import { RulesScreen } from "../rules_screen/RulesScreen";
import { AccountsScreen } from "../accounts_screen/AccountsScreen";
import { SettingsScreen } from "../settings_screen/SettingsScreen";
import { CategoriesScreen } from "../categories_screen/CategoriesScreen";
import { PreferencesContext } from "../firebaseio/PreferencesContext";

export function MainNav(): JSX.Element {
  const urlInfo = useContext(UrlContext);
  const userPrefs = useContext(PreferencesContext);

  const currentTab = urlInfo.currentTab ?? userPrefs.web.home_screen;

  return (
    <div className={buildClasses("h-screen", "w-screen", "flex", "p-3")}>
      <MainNavMenu />
      {mainNavToScreen(currentTab)}
    </div>
  );
}

function mainNavToScreen(mainNav: MainNavScreens): JSX.Element {
  switch (mainNav) {
    case MainNavScreens.ACCOUNTS:
      return <AccountsScreen />;
    case MainNavScreens.TRANSACTIONS:
      return <TransactionsScreen></TransactionsScreen>;
    case MainNavScreens.BUDGETS:
      return <BudgetScreen></BudgetScreen>;
    case MainNavScreens.CATEGORIES:
      return <CategoriesScreen />;
    case MainNavScreens.RULES:
      return <RulesScreen />;
    case MainNavScreens.SETTINGS:
      return <SettingsScreen />;
  }
}
