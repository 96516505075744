import { MainNavScreens } from "breadcommon";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RyeIcon } from "../rye/RyeIcon";
import { UrlContext } from "../UrlContext";
import { buildClasses } from "../utils/buildClasses";

export function MainNavButton({
  iconName,
  mainNavScreen,
}: {
  iconName: string;
  mainNavScreen: MainNavScreens;
}): JSX.Element {
  const urlInfo = useContext(UrlContext);
  const navigate = useNavigate();

  function setCurrentTab() {
    navigate("/" + mainNavScreen.toLowerCase());
  }

  function isSelected() {
    const currentTab = urlInfo.currentTab ?? MainNavScreens.TRANSACTIONS;
    return currentTab === mainNavScreen;
  }

  return (
    <div className={buildClasses("relative", "group", "select-none")}>
      <RyeIcon
        name={iconName}
        fill={isSelected()}
        size={"custom"}
        className={buildClasses(
          {
            if: isSelected(),
            then: buildClasses("text-purple", "bg-on-surface-200"),
            else: buildClasses(
              "text-on-surface-500",
              "hover:text-on-surface-800"
            ),
          },
          "text-[1.2rem]",
          "p-2",
          "rounded-md",
          "hover:cursor-pointer"
        )}
        onClick={setCurrentTab}
      />
      <MainNavButtonTooltip text={mainNavScreen.toLowerCase()} />
    </div>
  );
}

function MainNavButtonTooltip({ text }: { text: string }): JSX.Element {
  return (
    <div
      className={buildClasses(
        "absolute",
        "top-1/2",
        "-translate-y-1/2",
        "left-12",
        "bg-base",
        "text-on-surface-600",
        "border",
        "border-on-surface-300",
        "text-sm",
        "z-10",
        "shadow-md",
        "rounded-md",
        "px-5",
        "py-1",
        "capitalize",
        "transition-shadow",
        "hidden",
        "group-hover:block"
      )}
    >
      {text}
    </div>
  );
}
