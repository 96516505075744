import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { buildClasses } from "../utils/buildClasses";

export function RyeMainContentContainerBase({
  children,
  roundedTl = true,
  roundedTr = true,
  roundedBr = true,
  roundedBl = true,
  flexGrow = true,
  flexShrink = true,
  height = "full",
  padBottom = true,
  overflowY = "auto",
  zIndex = "10",
}: {
  children: React.ReactNode;
  flexGrow?: boolean;
  flexShrink?: boolean;
  height?: "full" | "auto";
  roundedTl?: boolean;
  roundedTr?: boolean;
  roundedBr?: boolean;
  roundedBl?: boolean;
  padBottom?: boolean;
  overflowY?: "auto" | "visible";
  zIndex?: "10" | "20";
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        { if: roundedTl, then: "rounded-tl-xl" },
        { if: roundedTr, then: "rounded-tr-xl" },
        { if: roundedBr, then: "rounded-br-xl" },
        { if: roundedBl, then: "rounded-bl-xl" },
        { if: flexGrow, then: "flex-grow", else: "flex-grow-0" },
        { if: flexShrink, then: "flex-shrink", else: "flex-shrink-0" },
        {
          switch: overflowY,
          cases: new Map([
            ["auto", "overflow-y-auto"],
            ["visible", "overflow-y-visible"],
          ]),
        },
        {
          switch: height,
          cases: new Map([
            ["full", "h-full"],
            ["auto", "h-auto"],
          ]),
        },
        "shadow-sm",
        "bg-surface"
      )}
    >
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "move" },
          overflow: {
            y: overflowY === "auto" ? "scroll" : "visible",
          },
        }}
        className={buildClasses(
          {
            switch: zIndex, // OverlayScrollbarsComponent creates a new stacking context, so we need to resolve stacking context ordering with z-index
            cases: new Map([
              ["10", "z-10"],
              ["20", "z-20"],
            ]),
          },
          {
            if: padBottom,
            then: buildClasses("pb-8"),
          },
          "h-full",
          "px-8"
        )}
      >
        {children}
      </OverlayScrollbarsComponent>
    </div>
  );
}

export const STANDARD_MAIN_CONTENT_CARD_PADDING_X = "px-8";
export const STANDARD_MAIN_CONTENT_CARD_PADDING_Y = "py-3";
