import { buildClasses } from "../utils/buildClasses";
import { RyeMainContentContainerBase } from "./RyeMainContentContainerBase";

export function RyeMainContentHeader({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <RyeMainContentContainerBase
      roundedBl={false}
      roundedBr={false}
      flexGrow={false}
      flexShrink={false}
      height="auto"
      padBottom={false}
      zIndex="20"
      overflowY="visible"
    >
      <div className={buildClasses("w-full", "flex", "items-center", "py-2")}>
        {children}
      </div>
    </RyeMainContentContainerBase>
  );
}
