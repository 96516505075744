import { useContext, useEffect, useState } from "react";
import { BudgetsContext } from "../firebaseio/BudgetsContext";
import styles from "./BudgetScreen.module.scss";
import { LoadingInitialDataContext } from "../LoadUser";
import { Skeleton } from "@mui/material";
import { VerticalSpacer } from "../utils/Utils";
import { CreateNewBudgetModal } from "./CreateNewBudgetModal";
import { RyeDropDown, DropDownEntry } from "../rye/RyeDropDown";
import { RyeButton } from "../rye/RyeButton";
import { buildClasses } from "../utils/buildClasses";
import { RyeMainContentHeader } from "../rye/RyeMainContentHeader";
import { RyeMainContentSpacer } from "../rye/RyeMainContentSpacer";
import { RyeMainContentCard } from "../rye/RyeMainContentCard";
import { RyeSegmentedButton } from "../rye/RyeSegmentedButton";
import { BudgetTracker } from "./BudgetTracker";
import { BudgetEditor } from "./BudgetEditor";

type BudgetScreenModes = "edit" | "view";

export function BudgetScreen(): JSX.Element {
  const [mode, setMode] = useState<BudgetScreenModes>("view");
  const budgets = useContext(BudgetsContext);
  const loadingInitialData = useContext(LoadingInitialDataContext);
  const [budgetId, setBudgetId] = useState<string | null>(null);

  useEffect(() => {
    if ((!budgetId || !budgets.has(budgetId)) && budgets.size > 0) {
      setBudgetId(
        Array.from(budgets.values())
          .sort(
            (a, b) =>
              b.endDateTimestampSecs.unix() - a.endDateTimestampSecs.unix()
          )
          .at(0)?.id ?? null
      );
    }
  }, [budgetId, budgets, budgets.size]);

  if (
    loadingInitialData.transactions ||
    loadingInitialData.categories ||
    loadingInitialData.budgets
  ) {
    return (
      <div>
        <Skeleton animation="wave" />
      </div>
    );
  }

  if (budgets.size === 0 || !budgetId) {
    return (
      <div className={styles.mainContainer}>
        <CreateNewBudgetButton />
        <VerticalSpacer height={20} />
        <div className={styles.card}>
          Use the Create New Budget button at the top of the page to create your
          first budget.
          <br />
          <br />
          WARNING: Budgets are very much still in Beta, so we might need to
          delete all your budgets in the near future to fix something. But feel
          free to play around with them for now.
        </div>
      </div>
    );
  } else if (budgetId === null || !budgets.has(budgetId)) {
    setBudgetId(Array.from(budgets.keys())[0]);
    return <div></div>; // Screen will re-render from setBudgetId() call so this empty div won't even be noticeable
  }

  const currentBudget = budgets.get(budgetId)!;

  let budgetDropDownEntries: DropDownEntry<string>[] = Array.from(
    budgets.values()
  )
    .sort(
      (a, b) => b.endDateTimestampSecs.unix() - a.endDateTimestampSecs.unix()
    )
    .map((budget) => {
      return { id: budget.id, text: budget.name, icon: null };
    });

  function getMainContent(): JSX.Element {
    switch (mode) {
      case "edit":
        return <BudgetEditor budget={currentBudget} />;
      case "view":
        return <BudgetTracker budget={currentBudget} />;
    }
  }

  return (
    <div
      className={buildClasses(
        "w-full",
        "h-full",
        "flex",
        "flex-col",
        "flex-grow",
        "flex-shrink",
        "min-w-1"
      )}
    >
      <RyeMainContentHeader>
        <CreateNewBudgetButton />
        <div className={buildClasses("w-7")} />
        <RyeDropDown<string>
          key={budgetId} // allows the initEntryId to take effect when budgetId is changed from a source other than the drop down (i.e. a budget is deleted)
          entries={budgetDropDownEntries}
          initEntryId={budgetId}
          onSelectEntry={(entry) => setBudgetId(entry.id)}
          searchable
          size="sm"
        ></RyeDropDown>
        <div className={buildClasses("w-7")} />
        <RyeSegmentedButton
          entries={[
            {
              id: "edit",
              displayText: "Edit",
              onClick: () => setMode("edit"),
            },
            {
              id: "view",
              displayText: "View",
              onClick: () => setMode("view"),
            },
          ]}
          initEntryId={mode}
        />
      </RyeMainContentHeader>
      <RyeMainContentSpacer direction="h" />
      <RyeMainContentCard roundedTl={false} roundedTr={false}>
        {getMainContent()}
      </RyeMainContentCard>
    </div>
  );
}

function CreateNewBudgetButton(): JSX.Element {
  const [createNewBudgetModalIsOpen, setCreateNewBudgetModalIsOpen] =
    useState<boolean>(false);

  return (
    <div>
      <RyeButton
        text="Create budget"
        icon="add"
        onClick={() => setCreateNewBudgetModalIsOpen(true)}
        size="sm"
        vibe="subdued"
      />
      {createNewBudgetModalIsOpen ? (
        <CreateNewBudgetModal
          isOpen={createNewBudgetModalIsOpen}
          setIsOpen={setCreateNewBudgetModalIsOpen}
        ></CreateNewBudgetModal>
      ) : null}
    </div>
  );
}
