import React from "react";
import { buildClasses } from "../utils/buildClasses";

export function SettingRow({
  label,
  picker,
  description,
}: {
  label: string;
  picker: React.ReactNode;
  description?: string;
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        "flex",
        "flex-row",
        "pl-4",
        "pb-4",
        "items-center"
      )}
    >
      <div
        className={buildClasses(
          "w-[500px]",
          "max-w-[500px]",
          "min-w-[150px]",
          "flex",
          "flex-col"
        )}
      >
        <div className={buildClasses("text-md", "text-on-surface-800")}>
          {label}
        </div>
        <div className={buildClasses("text-sm", "text-on-surface-400")}>
          {description}
        </div>
      </div>
      <div className={buildClasses("flex", "w-40", "justify-end")}>
        {picker}
      </div>
    </div>
  );
}
