import { FirestoreDocCategory } from "breadcommon";
import { useContext } from "react";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { CategoryGroupsContext } from "../firebaseio/CategoryGroupsContext";

export function useGetGroupedCategoriesForBudget() {
  const categories = useContext(CategoriesContext);
  const categoryGroups = useContext(CategoryGroupsContext);

  // NOTE: in Javascript, maps are iterated through in insertion order.
  const groupedCategories: Map<
    string,
    Map<string, FirestoreDocCategory>
  > = new Map();

  Array.from(categoryGroups.values())
    .sort((a, b) => a.order_position - b.order_position)
    .forEach((categoryGroup) => {
      groupedCategories.set(
        categoryGroup.id,
        new Map<string, FirestoreDocCategory>()
      );
    });
  Array.from(categories.values())
    .sort((a, b) => a.order_position - b.order_position)
    .filter((category) => category.type !== "Silent")
    .forEach((category) => {
      groupedCategories
        .get(category.category_group_id)!
        .set(category.id, category);
    });

  return groupedCategories;
}
