import { useEffect, useRef, useState } from "react";
import { buildClasses } from "../utils/buildClasses";
import { RyeButton } from "./RyeButton";
import { keyCodes } from "../utils/KeyCodes";
import { useKeyDown } from "../utils/customHooks";

type ActionButton = {
  text: string;
  variant?: "filled" | "outlined" | "transparent";
  vibe?: "subdued" | "ordinary" | "primary" | "danger";
  disabled?: boolean;
  action: () => void;
};

export function RyeModal({
  isOpen,
  close,
  content,
  actionButtons,
  title,
  showCloseX = true, // note: in general it is considered good UX to always include the `x` in the top-right, see https://ux.stackexchange.com/questions/84854/does-confirmation-dialog-need-a-close-x-button
  alignActionButtons = "end",
}: {
  isOpen: boolean;
  close: () => void;
  content: React.ReactNode;
  actionButtons: ActionButton[];
  title?: React.ReactNode;
  showCloseX?: boolean;
  alignActionButtons?: "start" | "center" | "between" | "end";
}) {
  const [useOpenStyles, setUseOpenStyles] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      setUseOpenStyles(false);
    } else {
      // The elements for the open modal have now rendered once, so we can
      // transition their bg colors, shadows, etc. to animate the modal opening.
      setUseOpenStyles(true);
      modalRef.current?.focus();
    }
  }, [isOpen]);

  function closeWithTransition() {
    setUseOpenStyles(false);
    setTimeout(() => close(), 150);
  }

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={buildClasses(
        {
          if: useOpenStyles,
          then: buildClasses("bg-on-surface-900/10"),
          else: buildClasses("bg-on-surface-900/0"),
        },
        "fixed",
        "inset-0",
        "z-[1000000000]",
        "w-screen",
        "h-screen",
        "flex",
        "items-center",
        "justify-center",
        "transition-colors"
      )}
    >
      <div
        ref={modalRef}
        className={buildClasses(
          {
            if: useOpenStyles,
            then: buildClasses("shadow-2xl", "opacity-100"),
            else: buildClasses("shadow-none", "opacity-0"),
          },
          "bg-surface-50",
          "rounded-xl",
          "m-3",
          "p-8",
          "flex-shrink",
          "transition-opacity",
          "transition-shadow",
          "z-0"
        )}
        tabIndex={0}
      >
        <Header
          title={title}
          showCloseX={showCloseX}
          close={closeWithTransition}
        />
        <div className={buildClasses("mt-7", "mb-8")}>{content}</div>
        <Footer
          actionButtons={actionButtons}
          alignActionButtons={alignActionButtons}
          close={closeWithTransition}
        />
      </div>
    </div>
  );
}

function Header({
  showCloseX,
  close,
  title,
}: {
  showCloseX: boolean;
  close: () => void;
  title?: React.ReactNode;
}): JSX.Element {
  const headerRef = useRef<HTMLDivElement>(null);

  useKeyDown(headerRef, showCloseX, handleKeyDown);

  function handleKeyDown(event: KeyboardEvent): void {
    if (event.code === keyCodes.ESC) {
      close();
    }
  }

  if (!title && !showCloseX) {
    return <div />;
  }

  return (
    <div
      ref={headerRef}
      className={buildClasses(
        "flex",
        "items-center",
        "justify-between",
        "text-lg",
        "font-medium"
      )}
    >
      <div className={buildClasses("pr-10")}>{title}</div>
      <RyeButton
        icon="close"
        variant="transparent"
        vibe="subdued"
        onClick={close}
      />
    </div>
  );
}

function Footer({
  actionButtons,
  alignActionButtons,
  close,
}: {
  actionButtons: ActionButton[];
  alignActionButtons?: "start" | "center" | "between" | "end";
  close: () => void;
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        {
          switch: alignActionButtons,
          cases: new Map([
            ["start", "justify-start"],
            ["center", "justify-center"],
            ["between", "justify-between"],
            ["end", "justify-end"],
          ]),
        },
        "flex",
        "items-center",
        "gap-5"
      )}
    >
      {actionButtons.map((button) => (
        <RyeButton
          key={button.text}
          text={button.text}
          vibe={button.vibe}
          variant={button.variant}
          disabled={button.disabled}
          onClick={() => {
            button.action();
            close();
          }}
        />
      ))}
    </div>
  );
}
