import { RyeMainContentContainerBase } from "./RyeMainContentContainerBase";

export function RyeMainContentCard({
  children,
  flexGrow = true,
  flexShrink = true,
  roundedTl = true,
  roundedTr = true,
  roundedBr = true,
  roundedBl = true,
  padBottom = true,
}: {
  children: React.ReactNode;
  flexGrow?: boolean;
  flexShrink?: boolean;
  roundedTl?: boolean;
  roundedTr?: boolean;
  roundedBr?: boolean;
  roundedBl?: boolean;
  padBottom?: boolean;
}): JSX.Element {
  return (
    <RyeMainContentContainerBase
      flexGrow={flexGrow}
      flexShrink={flexShrink}
      roundedTl={roundedTl}
      roundedTr={roundedTr}
      roundedBr={roundedBr}
      roundedBl={roundedBl}
      padBottom={padBottom}
    >
      {children}
    </RyeMainContentContainerBase>
  );
}
