import { useContext } from "react";
import { RyeIcon } from "../rye/RyeIcon";
import { buildClasses } from "../utils/buildClasses";
import { TransactionColumn } from "./TransactionColumn";
import { TransactionsNavContext, SortOrders } from "./TransactionsScreen";

export function SortIcon({
  column,
}: {
  column: TransactionColumn;
}): JSX.Element {
  const [transactionsNav, setTransactionsNav] = useContext(
    TransactionsNavContext
  );

  function SwitchSortOrder() {
    setTransactionsNav((prevState) => ({
      ...prevState,
      sortOrder:
        prevState.sortOrder === SortOrders.Descending
          ? SortOrders.Ascending
          : SortOrders.Descending,
    }));
  }

  function setSortColumn(column: TransactionColumn) {
    setTransactionsNav((prevState) => ({
      ...prevState,
      sortColumn: column,
      sortOrder: SortOrders.Descending,
    }));
  }

  let sortIconName: "arrow_circle_down" | "arrow_circle_up" =
    "arrow_circle_down";
  if (transactionsNav.sortOrder === SortOrders.Ascending) {
    sortIconName = "arrow_circle_up";
  }

  return transactionsNav.sortColumn === column ? (
    <RyeIcon
      name={sortIconName}
      size={"sm"}
      fill={true}
      className={buildClasses("text-purple", "cursor-pointer", "select-none")}
      onClick={SwitchSortOrder}
    ></RyeIcon>
  ) : (
    <RyeIcon
      name={"arrow_circle_down"}
      size={"sm"}
      fill={false}
      className={buildClasses(
        "text-on-surface-400",
        "cursor-pointer",
        "select-none",
        "invisible",
        "group-hover:visible"
      )}
      onClick={() => setSortColumn(column)}
    ></RyeIcon>
  );
}
