import { FirestoreDocCategory, FirestoreDocCategoryGroup } from "breadcommon";
import { RyeModal } from "../rye/RyeModal";
import { useContext } from "react";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { UserContext } from "../firebaseio/UserContext";
import { firestoreDeleteCategoryGroupAndUpdateOrder } from "../firebaseio/firestoreIo";
import { CategoryGroupsContext } from "../firebaseio/CategoryGroupsContext";

export function DeleteCategoryGroupModal({
  categoryGroup,
  close,
}: {
  categoryGroup: FirestoreDocCategoryGroup | null;
  close: () => void;
}): JSX.Element {
  const user = useContext(UserContext);
  const categoryGroups = useContext(CategoryGroupsContext);
  const categories = useContext(CategoriesContext);

  const numCategoriesInGroup = Array.from(categories.values()).filter(
    (category: FirestoreDocCategory) =>
      category.category_group_id === categoryGroup?.id
  ).length;

  function getContent(): JSX.Element | null {
    if (numCategoriesInGroup > 0) {
      return (
        <div>
          Groups cannot be deleted while they still contain categories.
          <br />
          To delete this group, please remove all categories from it first.
        </div>
      );
    }
    return null;
  }

  return (
    <RyeModal
      isOpen={categoryGroup !== null}
      close={close}
      actionButtons={[
        {
          text: "Cancel",
          vibe: "subdued",
          variant: "outlined",
          action: () => {},
        },
        {
          text: "Delete group",
          vibe: "danger",
          variant: "filled",
          action: () => {
            if (categoryGroup !== null) {
              firestoreDeleteCategoryGroupAndUpdateOrder(
                user.uid,
                categoryGroup,
                Array.from(categoryGroups.values()),
                Array.from(categories.values())
              );
            }
          },
          disabled: numCategoriesInGroup > 0,
        },
      ]}
      title={`Delete "${categoryGroup?.name}" group?`}
      content={getContent()}
    ></RyeModal>
  );
}
