import { useContext, useState } from "react";
import { RyeButton } from "../rye/RyeButton";
import { RyeMainContentCard } from "../rye/RyeMainContentCard";
import { RyeMainContentHeader } from "../rye/RyeMainContentHeader";
import { RyeMainContentSpacer } from "../rye/RyeMainContentSpacer";
import { RyeMainContentTitle } from "../rye/RyeMainContentTitle";
import { buildClasses } from "../utils/buildClasses";
import { CategoryGroupsContext } from "../firebaseio/CategoryGroupsContext";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import {
  ItemsGroup,
  RyeDraggableGroupedList,
} from "../rye/RyeDraggableGroupedList";
import {
  firestoreCreateCategoryGroup,
  firestoreUpdateCategoriesOrder,
  firestoreUpdateCategoryGroupsOrder,
} from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import dayjs from "dayjs";
import { CategoryItem } from "./CategoryItem";
import { CategoryGroupItem } from "./CategoryGroupItem";
import { createContextWithSetter } from "../utils/ContextUtils";
import { DeleteCategoryModal } from "./DeleteCategoryModal";
import { DeleteCategoryGroupModal } from "./DeleteCategoryGroupModal";
import { FirestoreDocCategory } from "breadcommon";
import { FirestoreDocCategoryGroup } from "breadcommon";

export const FocusCategoryIndexContext = createContextWithSetter<{
  groupId: string;
  categoryIndex: number;
} | null>(null);
export const FocusCategoryGroupIndexContext = createContextWithSetter<
  number | null
>(null);

export function CategoriesScreen(): JSX.Element {
  const [deleteCategoryModalCategory, setDeleteCategoryModalCategory] =
    useState<FirestoreDocCategory | null>(null);
  const [deleteCategoryGroupModalGroup, setDeleteCategoryGroupModalGroup] =
    useState<FirestoreDocCategoryGroup | null>(null);
  const [focusCategoryIndex, setFocusCategoryIndex] = useState<{
    groupId: string;
    categoryIndex: number;
  } | null>(null);
  const [focusCategoryGroupIndex, setFocusCategoryGroupIndex] = useState<
    number | null
  >(null);
  const user = useContext(UserContext);
  const categoryGroups = useContext(CategoryGroupsContext);
  const categories = useContext(CategoriesContext);

  const groupedCategoriesBuilder: Map<string, ItemsGroup> = new Map();
  Array.from(categoryGroups.values())
    .sort((a, b) => a.order_position - b.order_position)
    .forEach((categoryGroup) => {
      groupedCategoriesBuilder.set(categoryGroup.id, {
        id: categoryGroup.id,
        value: (
          <CategoryGroupItem
            categoryGroup={categoryGroup}
            openDeleteCategoryGroupModal={() =>
              setDeleteCategoryGroupModalGroup(categoryGroup)
            }
          />
        ),
        items: [],
      });
    });
  Array.from(categories.values())
    .sort((a, b) => a.order_position - b.order_position)
    .forEach((category) => {
      groupedCategoriesBuilder.get(category.category_group_id)!.items.push({
        id: category.id,
        value: (
          <CategoryItem
            category={category}
            openDeleteCategoryModal={() =>
              setDeleteCategoryModalCategory(category)
            }
          />
        ),
      });
    });
  const groupedCategories: ItemsGroup[] = Array.from(
    groupedCategoriesBuilder.values()
  );

  return (
    <FocusCategoryGroupIndexContext.Provider
      value={[focusCategoryGroupIndex, setFocusCategoryGroupIndex]}
    >
      <FocusCategoryIndexContext.Provider
        value={[focusCategoryIndex, setFocusCategoryIndex]}
      >
        <div
          className={buildClasses(
            "w-full",
            "h-full",
            "flex",
            "flex-col",
            "flex-grow",
            "flex-shrink",
            "min-w-1"
          )}
        >
          <RyeMainContentHeader>
            <RyeButton
              text="New Group"
              icon="add"
              size="sm"
              variant="outlined"
              vibe="subdued"
              onClick={() => {
                firestoreCreateCategoryGroup(user.uid, {
                  name: "New Group",
                  created_timestamp_secs: dayjs().unix(),
                  order_position: categoryGroups.size,
                });
                setFocusCategoryGroupIndex(categoryGroups.size);
              }}
            />
          </RyeMainContentHeader>
          <RyeMainContentSpacer direction={"h"} />
          <RyeMainContentCard roundedTl={false} roundedTr={false}>
            <div className={buildClasses("min-w-[870px]")}>
              <RyeMainContentTitle title={"Categories"} />
              <div className={buildClasses("-mt-7")}>
                <RyeDraggableGroupedList
                  inputGroupedItems={groupedCategories}
                  onGroupOrderUpdate={(newIdList: string[]) =>
                    firestoreUpdateCategoryGroupsOrder(user.uid, newIdList)
                  }
                  onItemOrderUpdate={(newGroupedItems: ItemsGroup[]) =>
                    firestoreUpdateCategoriesOrder(user.uid, newGroupedItems)
                  }
                  collapsedItemSpacerHeight={3}
                  collapsedGroupSpacerHeight={15}
                  backgroundColor="transparent"
                  groupClass="group/categorygroupitem"
                />
              </div>
              <div className="h-96" />
            </div>
          </RyeMainContentCard>
          <DeleteCategoryGroupModal
            categoryGroup={deleteCategoryGroupModalGroup}
            close={() => setDeleteCategoryGroupModalGroup(null)}
          />
          <DeleteCategoryModal
            category={deleteCategoryModalCategory}
            close={() => setDeleteCategoryModalCategory(null)}
          />
        </div>
      </FocusCategoryIndexContext.Provider>
    </FocusCategoryGroupIndexContext.Provider>
  );
}
