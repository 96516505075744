import { FirestoreDocCategory } from "breadcommon";
import { RyeModal } from "../rye/RyeModal";
import { firestoreDeleteCategoryByIdAndUpdateRelationships } from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { useContext, useState } from "react";
import { CategoriesContext } from "../firebaseio/CategoriesContext";
import { TransactionsContext } from "../firebaseio/TransactionsContext";
import { RyeDropDown } from "../rye/RyeDropDown";
import { buildClasses } from "../utils/buildClasses";

export function DeleteCategoryModal({
  category,
  close,
}: {
  category: FirestoreDocCategory | null;
  close: () => void;
}): JSX.Element {
  const [replacementCategoryId, setReplacementCategoryId] = useState<
    string | null
  >(null);
  const user = useContext(UserContext);
  const categories = useContext(CategoriesContext);
  const transactions = useContext(TransactionsContext);

  const numMatchingTransactions = Array.from(transactions.values()).filter(
    (transaction) => transaction.categoryId === category?.id
  ).length;

  function closeModal() {
    setReplacementCategoryId(null);
    close();
  }

  let content = (
    <div>This category is not currently assigned to any transactions.</div>
  );
  if (numMatchingTransactions !== 0) {
    content = (
      <div className={buildClasses("w-full")}>
        <div
          className={buildClasses("whitespace-pre")}
        >{`This category is currently assigned to ${numMatchingTransactions} transaction${
          numMatchingTransactions === 1 ? "" : "s"
        }.\nPlease choose which category to assign instead.`}</div>
        <div className="h-5" />
        <RyeDropDown<string>
          initEntryId={replacementCategoryId}
          entries={Array.from(categories).map(([id, category]) => {
            return {
              id: id,
              text: category.name,
              icon: category.emoji,
            };
          })}
          onSelectEntry={(entry) => setReplacementCategoryId(entry.id)}
          searchable
          width="full"
        />
      </div>
    );
  }

  return (
    <RyeModal
      isOpen={category !== null}
      close={closeModal}
      actionButtons={[
        {
          text: "Cancel",
          vibe: "subdued",
          variant: "outlined",
          action: () => {},
        },
        {
          text: "Delete category",
          vibe: "danger",
          variant: "filled",
          disabled:
            numMatchingTransactions > 0 && replacementCategoryId === null,
          action: () => {
            if (category !== null) {
              firestoreDeleteCategoryByIdAndUpdateRelationships(
                user.uid,
                category,
                replacementCategoryId,
                Array.from(categories.values())
              );
            }
          },
        },
      ]}
      title={`Delete "${category?.emoji}  ${category?.name}" category?`}
      content={content}
    ></RyeModal>
  );
}
