import { DollarAmount } from "../common/DollarAmount";
import { buildClasses } from "../utils/buildClasses";
import { DefaultTransactionColumnValue } from "./DefaultTransactionColumnValue";
import { TransactionColumn } from "./TransactionColumn";
import { TransactionColumnSizer } from "./TransactionColumnSizer";

export function TransactionColumnValueAmount({
  amount,
}: {
  amount: number;
}): JSX.Element {
  return (
    <TransactionColumnSizer column={TransactionColumn.AMOUNT}>
      <DefaultTransactionColumnValue align="RIGHT">
        <div
          className={buildClasses({
            if: amount > 0,
            then: buildClasses("text-green", "font-medium"),
          })}
        >
          <DollarAmount
            n={amount}
            includeDecimals={true}
            showPlus={true}
            showMinus={true}
            showDollarSign={false}
            align="right"
          />
        </div>
      </DefaultTransactionColumnValue>
    </TransactionColumnSizer>
  );
}
