import { BreadTransaction } from "breadcommon";
import { Fragment, useContext, useState } from "react";
import { TransactionRowContext } from "./TransactionRow";
import { UserContext } from "../firebaseio/UserContext";
import { firestoreAddRuleAndUpdateTransactions } from "../firebaseio/firestoreIo";
import { FirestoreDocRule } from "breadcommon";
import { buildClasses } from "../utils/buildClasses";
import { RyeIcon } from "../rye/RyeIcon";
import { TransactionColumnSizer } from "./TransactionColumnSizer";
import { TransactionColumn } from "./TransactionColumn";
import { InlineRuleEditorModal } from "./InlineRuleEditorModal";

export function TransactionColumnValueRule(props: {
  transaction: BreadTransaction;
  activeRule: FirestoreDocRule | null;
  createRuleFromTransactionWithoutId: (
    categoryIdAction: string
  ) => Omit<FirestoreDocRule, "id">;
  ruleEditorIsOpen: boolean;
  openRuleEditor: () => void;
  closeRuleEditor: () => void;
}) {
  const { transactionSelected, ruleForFieldHighlighting } = useContext(
    TransactionRowContext
  );
  const user = useContext(UserContext);
  const [activeRuleIcon, setActiveRuleIcon] = useState<string>("gavel");

  function handleClick(
    activeRule: FirestoreDocRule | null,
    categoryIdAction: string
  ) {
    if (!activeRule) {
      firestoreAddRuleAndUpdateTransactions(
        user.uid,
        props.createRuleFromTransactionWithoutId(categoryIdAction)
      );
    } else {
      props.openRuleEditor();
    }
  }

  function handleMouseEnter(
    matchingRule: FirestoreDocRule | null,
    categoryIdAction: string
  ) {
    if (matchingRule === null) {
      matchingRule = {
        id: "",
        ...props.createRuleFromTransactionWithoutId(categoryIdAction),
      };
    }
    ruleForFieldHighlighting.set(matchingRule);
    setActiveRuleIcon("edit");
  }

  function handleMouseLeave(matchingRule: FirestoreDocRule | null) {
    ruleForFieldHighlighting.set(null);
    setActiveRuleIcon("gavel");
  }

  const transactionCategoryId = props.transaction.categoryId;
  if (transactionCategoryId === null) {
    // Don't allow creating a rule from an uncategorized transaction,
    // since it doesn't make sense.
    return <div className={buildClasses("h-8", "w-8")} />;
  }

  let icon = (
    <RyeIcon
      className={buildClasses(
        "invisible",
        "group-hover:visible",
        "text-on-surface-400"
      )}
      name={"gavel"}
      size={"sm"}
    ></RyeIcon>
  );

  if (props.activeRule !== null) {
    icon = (
      <RyeIcon
        className={buildClasses("text-purple")}
        name={activeRuleIcon}
        size={"sm"}
        tooltip="Click to edit the rule applied to this transaction"
      ></RyeIcon>
    );
  }

  return (
    <Fragment>
      <TransactionColumnSizer column={TransactionColumn.RULE}>
        <div
          onClick={() => handleClick(props.activeRule, transactionCategoryId)}
          className={buildClasses(
            {
              if: transactionSelected,
              then: buildClasses(
                "hover:bg-purple-50",
                "hover:border-purple-400"
              ),
              else: buildClasses(
                "hover:bg-surface-400",
                "hover:border-on-surface-300"
              ),
            },
            "flex",
            "items-center",
            "justify-center",
            "h-8",
            "w-8",
            "rounded-full",
            "border-tiny",
            "border-transparent",
            "cursor-pointer",
            "group"
          )}
          onMouseEnter={() =>
            handleMouseEnter(props.activeRule, transactionCategoryId)
          }
          onMouseLeave={() => handleMouseLeave(props.activeRule)}
        >
          {icon}
        </div>
      </TransactionColumnSizer>
      {props.activeRule !== null ? (
        <InlineRuleEditorModal
          rule={props.activeRule}
          transaction={props.transaction}
          isOpen={props.ruleEditorIsOpen}
          close={() => props.closeRuleEditor()}
        />
      ) : null}
    </Fragment>
  );
}
