import { BreadTransaction } from "./BreadTransaction";
import { FirestoreDocRule } from "./FirestoreDocRule";
import { Predicate } from "./Predicates";
import * as P from "./Predicates";

// We use null in the rule criteria to indicate that a field is not active in
// the rule (i.e that field should be ignored for matching purposes).
// However, some of our field values can be null (i.e. merchant can be null).
// In the case of a null field value, we convert it to a placeholder value (in the case of strings this is the empty string).
export function getNullFieldValueCriterionEquivalent() {
  return "";
}

/**
 * Best INACTIVE Match: this is the rule with the minimum order position that matches the transaction, but where the category does NOT match the one already set on the transaction
 * Best ACTIVE Match: this is the rule with the minimum order position that matches the transaction AND the category already set on that transcation
 * 
 * "Best Match":
 *   - for reviewed transcations, return the best active match if one can be found, otherwise fall back to the best inactive match
 *   - for unreviewed transactions, take the best match regardless of whether it is active or not
 * 
 * If no match is found, this function returns {
 *     bestMatchRule: null, 
 *     bestMatchRuleIsActive: false
 *   }
 */
export function matchTransactionToRule(
  transaction: BreadTransaction,
  rules: Map<string, FirestoreDocRule>
): {
  bestMatchRule: FirestoreDocRule | null,
  bestMatchRuleIsActive: boolean // false when bestMatchRule is null or bestMatchRule's category is not the transaction's category
} {
  let bestMatchRule: FirestoreDocRule | null = null;
  let bestMatchRuleIsActive = false;
  for (const [_ruleId, thisRule] of rules) {
    if (transactionMatchesSpecificRule(transaction, thisRule)) {
      const thisRuleIsActive = thisRule.action.category_id === transaction.categoryId;
      if (bestMatchRule === null) {
        bestMatchRule = thisRule;
        bestMatchRuleIsActive = thisRuleIsActive;
      } else if (!bestMatchRuleIsActive && thisRuleIsActive && transaction.reviewed) {
        bestMatchRule = thisRule;
        bestMatchRuleIsActive = thisRuleIsActive;
      } else if (bestMatchRuleIsActive && !thisRuleIsActive && transaction.reviewed) {
        continue;
      } else if (thisRule.order_position < bestMatchRule.order_position) {
        // either thisRuleIsActive must equal bestMatchRuleIsActive here OR the transaction is unreviewed
        bestMatchRule = thisRule;
        bestMatchRuleIsActive = thisRuleIsActive;
      } else {
        continue;
      }
    }
  }
  return { bestMatchRule, bestMatchRuleIsActive };
}

/**
 * This checks whether a specific rule matches a transaction.
 * You usually want to use the `matchTransactionToRule` function instead.
 * You should ABSOLUTELY NOT loop over this function to find a matching rule.
 */
export function transactionMatchesSpecificRule(
  transaction: BreadTransaction,
  rule: FirestoreDocRule
): boolean {
  return predicateFromRule(rule).applyTo(transaction);
}

function predicateFromRule(
  rule: FirestoreDocRule
): Predicate {
  const nullPlaceholder = getNullFieldValueCriterionEquivalent();
  const criteria = rule.criteria;
  const predicates: Predicate[] = [];
  if (criteria.account_id === nullPlaceholder) {
    predicates.push(
      P.IsNullOrEmpty(
        P.Field<BreadTransaction, 'account_id'>('account_id'),
      ));
  } else if (criteria.account_id != null) {
    predicates.push(
      P.Equals(
        P.Field<BreadTransaction, 'account_id'>('account_id'),
        P.Literal(criteria.account_id)
      ));
  }
  if (criteria.merchant_name === nullPlaceholder) {
    predicates.push(
      P.IsNullOrEmpty(
        P.NestedField<BreadTransaction, 'merchant', 'name'>('merchant', 'name'),
      ));
  } else if (criteria.merchant_name != null) {
    predicates.push(
      P.Equals(
        P.NestedField<BreadTransaction, 'merchant', 'name'>('merchant', 'name'),
        P.Literal(criteria.merchant_name)
      ));
  }
  if (criteria.description === nullPlaceholder) {
    predicates.push(
      P.IsNullOrEmpty(
        P.Field<BreadTransaction, 'description'>('description'),
      ));
  } else if (criteria.description != null) {
    predicates.push(
      P.Equals(
        P.Field<BreadTransaction, 'description'>('description'),
        P.Literal(criteria.description)
      ));
  }
  if (criteria.partial_description != null) {
    predicates.push(P.SubstringCI(
      P.Field<BreadTransaction, 'description'>('description'),
      P.Literal(criteria.partial_description!)));
  }
  if (criteria.type === nullPlaceholder) {
    predicates.push(
      P.IsNullOrEmpty(
        P.Field<BreadTransaction, 'type'>('type'),
      ));
  } else if (criteria.type != null) {
    predicates.push(
      P.Equals(
        P.Field<BreadTransaction, 'type'>('type'),
        P.Literal(criteria.type)
      ));
  }
  if (criteria.subtype === nullPlaceholder) {
    predicates.push(
      P.IsNullOrEmpty(
        P.Field<BreadTransaction, 'subtype'>('subtype'),
      ));
  } else if (criteria.subtype != null) {
    predicates.push(
      P.Equals(
        P.Field<BreadTransaction, 'subtype'>('subtype'),
        P.Literal(criteria.subtype)
      ));
  }
  return P.All(...predicates);
}