import { useContext } from "react";
import styles from "./BudgetScreen.module.scss";
import { FirestoreDocCategory } from "breadcommon";
import { BreadBudget, BudgetLineItem } from "breadcommon/src/BreadBudget";
import { DollarAmount } from "../common/DollarAmount";
import {
  firestoreAddEmptyBudgetLineItem,
  firestoreRemoveBudgetLineItem,
  firestoreUpdateBudgetLineItem,
} from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { HorizontalSpacer } from "../utils/Utils";
import { useGetGroupedCategoriesForBudget } from "./useGetGroupedCategoriesForBudget";

export function BudgetEditor(props: { budget: BreadBudget }): JSX.Element {
  const groupedCategories = useGetGroupedCategoriesForBudget();
  const user = useContext(UserContext);

  const categories = Array.from(groupedCategories.values()).reduce<
    Map<string, FirestoreDocCategory>
  >((agg, current) => {
    current.forEach((category, id) => agg.set(id, category));
    return agg;
  }, new Map());

  // make sure budget has all and only existing categories
  props.budget.line_items.forEach(async (_, category_id) => {
    if (!categories.has(category_id)) {
      await firestoreRemoveBudgetLineItem(
        user.uid,
        props.budget.id,
        category_id
      );
    }
  });
  categories.forEach(async (_, category_id) => {
    if (!props.budget.line_items.has(category_id)) {
      await firestoreAddEmptyBudgetLineItem(
        user.uid,
        props.budget.id,
        category_id
      );
    }
  });

  function line(
    c: FirestoreDocCategory,
    line_item: BudgetLineItem
  ): JSX.Element {
    return (
      <div key={c.id} className={styles.lineItem}>
        <div className={styles.categoryName}>{c.emoji + "   " + c.name}</div>
        <HorizontalSpacer width={20}></HorizontalSpacer>
        <input
          key={props.budget.id} // reset the default value when the budget changes
          className={styles.formulaInput}
          type="text"
          defaultValue={line_item.formula}
          onChange={async (e) => {
            const newFormula = e.target.value;
            await firestoreUpdateBudgetLineItem(
              user.uid,
              props.budget.id,
              c.id,
              newFormula
            );
          }}
        />
        <HorizontalSpacer width={20}></HorizontalSpacer>
        <div>
          {!line_item.formula_is_valid ? (
            "Invalid Expression"
          ) : (
            <DollarAmount
              n={line_item.amount}
              includeDecimals={false}
              showPlus={true}
              showMinus={true}
              showDollarSign={true}
              align="left"
            />
          )}
        </div>
      </div>
    );
  }

  const lines: JSX.Element[] = Array.from(categories.values()).map((c) =>
    line(c, props.budget.line_items.get(c.id)!)
  );
  return <div>{lines}</div>;
}
