import { BreadTransaction } from "breadcommon";
import { firestoreUpdateTransaction } from "../firebaseio/firestoreIo";
import { useContext } from "react";
import { TransactionRowContext } from "./TransactionRow";
import { UserContext } from "../firebaseio/UserContext";
import { TransactionColumnSizer } from "./TransactionColumnSizer";
import { TransactionColumn } from "./TransactionColumn";
import { buildClasses } from "../utils/buildClasses";
import { TransactionReviewedIcon } from "./TransactionReviewedIcon";

export function TransactionColumnValueReviewed(props: {
  transaction: BreadTransaction;
}) {
  const { transactionSelected } = useContext(TransactionRowContext);
  const user = useContext(UserContext);

  function handleClick() {
    firestoreUpdateTransaction(
      user.uid,
      props.transaction.id,
      {
        manual_data: { reviewed: !props.transaction.reviewed },
      },
      null
    );
  }

  return (
    <TransactionColumnSizer column={TransactionColumn.REVIEWED}>
      <div
        onClick={handleClick}
        className={buildClasses(
          {
            if: transactionSelected,
            then: buildClasses("hover:bg-purple-50", "hover:border-purple-400"),
            else: buildClasses(
              "hover:bg-surface-400",
              "hover:border-on-surface-300"
            ),
          },
          "flex",
          "items-center",
          "justify-center",
          "h-8",
          "w-8",
          "rounded-full",
          "cursor-pointer",
          "border-tiny",
          "border-transparent"
        )}
      >
        <TransactionReviewedIcon reviewed={props.transaction.reviewed} />
      </div>
    </TransactionColumnSizer>
  );
}
