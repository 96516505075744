import { Dayjs } from "dayjs";
import yaml from 'js-yaml';

enum OpName {
  Literal = 'lit',
  Field = 'fld',
  NestedField = 'nested_fld',
  Equals = 'eq',
  IsNull = 'is_null',
  Not = 'not',
  All = 'all',
  Any = 'any',
  IsNullOrEmpty = 'null_or_empty',
  SubstringCI = 'substr_ci',
  GreaterThanEqualTo = 'gteq',
  LessThanEqualTo = 'lteq',
}

type SerializableOp = {
  name: OpName,
  args: SerializableOp[] | any[],
};

type Op<TOut> = {
  asSerializable: () => SerializableOp,
  applyTo: <TObj>(obj: TObj) => TOut,
}

// Outside of this module, we should really only be writing
// functions that pass around Operations that output booleans, 
// not the lower level operations.
export type Predicate = Op<boolean>;

//// Values (leaf nodes of the query tree) ////

// Holds a literal value, like a number, string, or Dayjs.
// Dayjs ends up serialized as unixtime.
// TODO figure out how to handle null, lists, maybe dates better
// wondering if they all need to be little operations.
// But I might need to start implementing deserialization to see what makes sense
type acceptedLiterals = number | string | boolean | null | undefined;
export function Literal<T extends acceptedLiterals>(val: T): Op<T> {
  return {
    asSerializable: () => ({
      name: OpName.Literal,
      args: [val]
    }),
    applyTo: (_obj) => val,
  }
};

// Gets a field from an object
export function Field<
  TObj,
  TField extends keyof TObj
>(
  field: TField
): Op<TObj[TField]> {
  return {
    asSerializable: () => ({
      name: OpName.Field,
      args: [field]
    }),
    applyTo: (obj: unknown): TObj[TField] => (obj as TObj)[field] as TObj[TField],
  }
};

/**
 * Gets a field from a object field of an object. Like if you wanted to get "this one" from
 * {
 *   field_a: "asdf",
 *   field_b: {
 *     field_x: "this one"
 *     field_y: "asdfasdf"
 *   }
 * }
 * you would use NestedField(field_b, field_x)
 */
export function NestedField<
  TObj,
  TField extends keyof TObj,
  TNestedField extends keyof NonNullable<TObj[TField]>
>(
  field: TField,
  nestedField: TNestedField
): Op<NonNullable<TObj[TField]>[TNestedField] | null> {
  return {
    asSerializable: () => ({
      name: OpName.NestedField,
      args: [field, nestedField]
    }),
    applyTo: (obj: unknown): NonNullable<TObj[TField]>[TNestedField] | null => {
      const fieldObj = (obj as TObj)[field];
      if (fieldObj == null) {
        return null;
      }
      return fieldObj[nestedField];
    }
  }
};

//// General operations ////

// Returns true iff null or undefined
export function IsNull(arg: Op<any>): Op<boolean> {
  return {
    asSerializable: () => ({
      name: OpName.IsNull,
      args: [arg.asSerializable()]
    }),
    applyTo: (obj) => arg.applyTo(obj) == null
  }
};

// Logical NOT (returns true iff the passed-in Op returns false)
export function Not(arg: Op<boolean>): Op<boolean> {
  return {
    asSerializable: () => ({
      name: OpName.Not,
      args: [arg.asSerializable()]
    }),
    applyTo: (obj) => !arg.applyTo(obj)
  }
};

// Same as `===`, with a special case to make null Equal undefined because
// JSON serialization can't distinguish between them.
export function Equals<TArg>(arg1: Op<TArg>, arg2: Op<TArg>): Op<boolean> {
  return {
    asSerializable: () => ({
      name: OpName.Equals,
      args: [arg1.asSerializable(), arg2.asSerializable()]
    }),
    applyTo: (obj) => {
      const a = arg1.applyTo(obj);
      const b = arg2.applyTo(obj);
      return a === b || (a == null && b == null);
    }
  }
};

// Returns true iff all arguments return true. Global AND.
export function All(...args: Op<boolean>[]): Op<boolean> {
  return {
    asSerializable: () => ({
      name: OpName.All,
      args: args.map((arg) => arg.asSerializable())
    }),
    applyTo: (obj) => args.every((arg) => arg.applyTo(obj))
  }
};

// Returns true iff any of the arguments return true. Global OR.
export function Any(...args: Op<boolean>[]): Op<boolean> {
  return {
    asSerializable: () => ({
      name: OpName.Any,
      args: args.map((arg) => arg.asSerializable())
    }),
    applyTo: (obj) => args.some((arg) => arg.applyTo(obj))
  }
};

//// String-specific operations ////

// Returns true iff string is null or undefined or the empty string ""
export function IsNullOrEmpty(arg: Op<string | null | undefined>): Op<boolean> {
  return {
    asSerializable: () => ({
      name: OpName.IsNullOrEmpty,
      args: [arg.asSerializable()]
    }),
    applyTo: (obj) => {
      const str = arg.applyTo(obj);
      return str == null || str === "";
    }
  };
}

// Returns true iff the first argument contains second arguments as a substring (case insensitive)
export function SubstringCI(arg1: Op<string | null | undefined>, arg2: Op<string>): Op<boolean> {
  return {
    asSerializable: () => ({
      name: OpName.SubstringCI,
      args: [arg1.asSerializable(), arg2.asSerializable()]
    }),
    applyTo: (obj) => {
      const haystack = arg1.applyTo(obj);
      return haystack != null && haystack.toLowerCase().includes(
        arg2.applyTo(obj).toLowerCase()
      )
    }
  }
};


//// Number-specific operations ////

// Returns true iff the first argument >= the second argument
export function GreaterThanEqualTo(arg1: Op<number>, arg2: Op<number>): Op<boolean> {
  return {
    asSerializable: () => ({
      name: OpName.GreaterThanEqualTo,
      args: [arg1.asSerializable(), arg2.asSerializable()]
    }),
    applyTo: (obj) => {
      return arg1.applyTo(obj) >= arg2.applyTo(obj);
    }
  }
};

// Returns true iff the first argument <= the second argument
export function LessThanEqualTo(arg1: Op<number>, arg2: Op<number>): Op<boolean> {
  return {
    asSerializable: () => ({
      name: OpName.LessThanEqualTo,
      args: [arg1.asSerializable(), arg2.asSerializable()]
    }),
    applyTo: (obj) => {
      return arg1.applyTo(obj) <= arg2.applyTo(obj);
    }
  }
};

//// Serialization ////

export function serialize(p: Predicate, prettyPrint: boolean = false): string {
  return JSON.stringify(p.asSerializable(), null, prettyPrint ? 2 : 0)
}

export function deserialize(predicate_json: string): Predicate {
  function fromSerializable(op: SerializableOp): Op<any> {
    const args: SerializableOp[] = op.args; // this won't be true for leafs
    switch (op.name) {
      case OpName.Literal:
        return Literal(op.args[0] as acceptedLiterals);
      case OpName.Field:
        return Field<any, any>(op.args[0] as string);
      case OpName.NestedField:
        return NestedField<any, any, any>(op.args[0] as string, op.args[1] as string);
      case OpName.IsNull:
        return IsNull(fromSerializable(args[0]));
      case OpName.Not:
        return Not(fromSerializable(args[0]));
      case OpName.Equals:
        return Equals(fromSerializable(args[0]), fromSerializable(args[1]));
      case OpName.All:
        return All(...args.map((arg) => fromSerializable(arg)));
      case OpName.Any:
        return Any(...args.map((arg) => fromSerializable(arg)));
      case OpName.IsNullOrEmpty:
        return IsNullOrEmpty(fromSerializable(args[0]));
      case OpName.SubstringCI:
        return SubstringCI(fromSerializable(args[0]), fromSerializable(args[1]));
      case OpName.GreaterThanEqualTo:
        return GreaterThanEqualTo(fromSerializable(args[0]), fromSerializable(args[1]));
      case OpName.LessThanEqualTo:
        return LessThanEqualTo(fromSerializable(args[0]), fromSerializable(args[1]));
    }
  }

  const serializable_p = JSON.parse(predicate_json);
  return fromSerializable(serializable_p);

}