import { Fragment, useState } from "react";
import { buildClasses } from "../utils/buildClasses";

export type SegmentedButtonEntry = {
  id: string;
  displayText: string;
  onClick: () => void;
};

export function RyeSegmentedButton({
  entries,
  initEntryId,
}: {
  entries: SegmentedButtonEntry[];
  initEntryId: string;
}) {
  const [selectedEntryId, setSelectedEntryId] = useState(initEntryId);

  return (
    <div
      className={buildClasses(
        "flex",
        "items-center",
        "h-7",
        "border",
        "border-on-surface-300",
        "rounded-md",
        "text-sm",
        "overflow-hidden"
      )}
    >
      {entries.map((entry, index) => (
        <Fragment>
          <Entry
            key={entry.id}
            entry={entry}
            isSelected={selectedEntryId === entry.id}
            setSelectedEntryId={setSelectedEntryId}
          ></Entry>
          {index !== entries.length - 1 ? <Divider /> : null}
        </Fragment>
      ))}
    </div>
  );
}

function Entry(props: {
  entry: SegmentedButtonEntry;
  isSelected: boolean;
  setSelectedEntryId: React.Dispatch<React.SetStateAction<string>>;
}) {
  function handleClick() {
    props.setSelectedEntryId(props.entry.id);
    props.entry.onClick();
  }

  return (
    <div
      className={buildClasses(
        { if: props.isSelected, then: buildClasses("bg-purple-100") },
        "flex",
        "items-center",
        "h-full",
        "px-3",
        "cursor-pointer",
        "transition-all"
      )}
      onClick={handleClick}
    >
      {props.entry.displayText}
    </div>
  );
}

function Divider() {
  return (
    <div className={buildClasses("h-full", "w-px", "bg-on-surface-300")}></div>
  );
}
