import { useContext } from "react";
import { PreferencesContext } from "../firebaseio/PreferencesContext";
import { SettingRow } from "./SettingRow";
import { MainNavScreens } from "breadcommon";
import {
  setWebHideDollarAmountsPreference,
  setWebHomeScreenPreference,
} from "../firebaseio/preferencesIo";
import { UserContext } from "../firebaseio/UserContext";
import { RyeToggle } from "../rye/RyeToggle";
import { RyeDropDown } from "../rye/RyeDropDown";

export function GeneralSettings(): JSX.Element {
  const user = useContext(UserContext);
  const userPrefs = useContext(PreferencesContext);

  return (
    <div>
      <SettingRow
        label="Home Screen"
        description="This is the page that will be opened when you open Bread"
        picker={
          <RyeDropDown<MainNavScreens>
            entries={[
              { id: MainNavScreens.ACCOUNTS, text: "Accounts", icon: null },
              {
                id: MainNavScreens.TRANSACTIONS,
                text: "Transactions",
                icon: null,
              },
              { id: MainNavScreens.BUDGETS, text: "Budgets", icon: null },
            ]}
            initEntryId={userPrefs.web.home_screen}
            onSelectEntry={async (entry) =>
              await setWebHomeScreenPreference(user.uid, entry.id)
            }
            width="full"
          />
        }
      />
      <SettingRow
        label="Hide Dollar Amounts"
        description='All dollar amounts on the site will be shown simply as "##"'
        picker={
          <RyeToggle
            initIsChecked={userPrefs.web.hide_dollar_amounts}
            onChange={async (newState: boolean) =>
              await setWebHideDollarAmountsPreference(user.uid, newState)
            }
          />
        }
      />
    </div>
  );
}
