import { BreadInstitution } from "breadcommon";
import { BreadAccount } from "breadcommon";
import { useContext, useEffect } from "react";
import { InstitutionsContext } from "../firebaseio/InstitutionsContext";

// When the same element has multiple of the same kind of event listener,
// the order in which they execute is non-deterministic.
// Furthermore, having these event listener on the same element
// (i.e. all on window) seems to cause problems when rerenders are triggered by
// the event handler and some of the listeners never get called.
//
// This problem seems to be avoided by instead having different listeners
// attach to different elements (i.e. one on window and one on document).
// This also gives us the option of having a heirarchy and having the ability
// to therefore control the order of listener execution and stop
// propogation (i.e. with event.stopPropagation).
function useClickOutsideElement(
  elementRef: React.RefObject<HTMLElement>,
  action: () => void,
  bindingCondition: boolean,
  bindTarget: Window | Document
) {
  useEffect(() => {
    function handleClick(event: Event) {
      if (
        elementRef.current &&
        !elementRef.current.contains(event.target as Node)
      ) {
        action();
      } else {
      }
    }

    if (bindingCondition) {
      bindTarget.addEventListener("mousedown", handleClick);
      return () => {
        // Unbind the event listener on clean up,
        // which runs every time dependencies change.
        bindTarget.removeEventListener("mousedown", handleClick);
      };
    }
  }, [elementRef, action, bindingCondition, bindTarget]);
}

function useKeyDown<T>(
  elementRef: React.RefObject<T>,
  shouldListen: boolean,
  action: (this: Window, ev: KeyboardEvent) => void
) {
  useEffect(() => {
    if (shouldListen) {
      window.addEventListener("keydown", action);
    } else {
      window.removeEventListener("keydown", action);
    }
    return () => {
      // Unbind the event listener on clean up,
      //   does nothing if the event has already been removed
      window.removeEventListener("keydown", action);
    };
  }, [elementRef, shouldListen, action]);
}

function useKeyUp(
  elementRef: React.RefObject<HTMLElement>,
  shouldListen: boolean,
  action: (this: Window, ev: KeyboardEvent) => void
) {
  useEffect(() => {
    if (shouldListen) {
      window.addEventListener("keyup", action);
    } else {
      window.removeEventListener("keyup", action);
    }
    return () => {
      // Unbind the event listener on clean up,
      //   does nothing if the event has already been removed
      window.removeEventListener("keyup", action);
    };
  }, [elementRef, shouldListen, action]);
}

function useGetInstitutionFromAccount(
  account: BreadAccount | undefined | null
): BreadInstitution | null | undefined {
  const institutions = useContext(InstitutionsContext);

  if (!account) {
    return null;
  }

  let institution = null;
  if (
    account.institution_id !== null &&
    institutions.has(account.institution_id)
  ) {
    institution = institutions.get(account.institution_id);
  }
  return institution;
}

export {
  useClickOutsideElement,
  useKeyDown,
  useKeyUp,
  useGetInstitutionFromAccount,
};
